import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  TextField,
  Button,
  Snackbar,
  Alert,
  InputAdornment,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import config from "../config/api";
import backgroundImage from "./../assets/images/bgImage.png";
const baseUrl = process.env.REACT_APP_API_BASE_URL;
const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [emailError, setEmailError] = useState("");

  const navigate = useNavigate();

  const handleForgotPassword = async () => {
    if (!email) {
      setEmailError("Please enter your email");
      return;
    }
    setLoading(true);
    // Add API call logic here to send reset link
    try {
      const response = await fetch(`${baseUrl}/emailVerification`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Frontend-Origin": window.location.origin,
        },
        body: JSON.stringify({ email: email.toLowerCase() }),
      });

      if (response.ok) {
        const data = await response.json();
        setToastMessage(data.message);
        setLoading(false);
        setSuccess(true);
      } else {
        const errorData = await response.json();
        setToastMessage(errorData.message);
        setLoading(false);
        setError(true);
      }
    } catch (error) {
      setLoading(false);
      setError(true);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        width: "100vw",
        height: "100vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "400px",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "2rem",
          borderRadius: "16px",
          backgroundColor: "rgba(255, 255, 255, 0.95)",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.15)",
          "@media (max-width: 480px)": {
            width: "60%",
            padding: "1.2rem",
          },
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: "20px", sm: "24px" },
            fontWeight: "bold",
            color: "#003D78",
            mb: { xs: 1, md: 2 },
            position: "relative",
            "&::before": {
              content: '""',
              position: "absolute",
              bottom: "-2px",
              left: 0,
              width: "32px",
              height: "3px",
              backgroundColor: "#2CC4EF",
            },
          }}
        >
          Forgot Password
        </Typography>

        <TextField
          size="small"
          fullWidth
          label="Email"
          variant="standard"
          margin="normal"
          InputLabelProps={{
            sx: {
              color: "#003D78",
              "&.Mui-focused": { color: "primary.main" },
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EmailOutlinedIcon
                  sx={{
                    color: "#003D78",
                    fontSize: { xs: "1rem", sm: "1.25rem" },
                  }}
                />
              </InputAdornment>
            ),
            sx: {
              padding: "2px",
              fontSize: { xs: "12px", sm: "14px" },
              "&:before": { borderBottomColor: "#003D78" },
              "&:after": { borderBottomColor: "primary.main" },
            },
          }}
          value={email}
          error={!email && emailError}
          helperText={emailError}
          onChange={(e) => {
            setEmail(e.target.value);
            setEmailError("");
          }}
        />

        <Button
          size="large"
          fullWidth
          sx={{
            mt: 2,
            fontWeight: "bold",
            borderRadius: "8px",
            backgroundColor: "#003D78",
            textTransform: "none",
            "&:hover": { backgroundColor: "primary.main", color: "#fff" },
            transition: "background-color 0.3s ease-in-out",
          }}
          variant="contained"
          onClick={handleForgotPassword}
          disabled={loading}
        >
          {loading ? (
            <CircularProgress size={22} sx={{ color: "#fff" }} />
          ) : (
            "Send Reset Link"
          )}
        </Button>

        <Typography
          sx={{
            mt: 2,
            color: "#003D78",
            cursor: "pointer",
            fontSize: { xs: "12px", sm: "14px" },
            "&:hover": { textDecoration: "underline" },
          }}
          onClick={() => navigate("/")}
        >
          Back to Login
        </Typography>
      </Box>

      <Snackbar
        open={success || error}
        autoHideDuration={3000}
        onClose={() => {
          setSuccess(false);
          setError(false);
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => {
            setSuccess(false);
            setError(false);
          }}
          severity={success ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {toastMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ForgotPassword;
