import config from "../config/api";

export const getApplicationHistory = (userId, chatId) => async (dispatch) => {
  try {
    dispatch({
      type: "APPLICATION_HISTORY_REQUEST",
    });
    const endpoint = `${config.petitionUrl}/get_application`;
    const data = { user_id: userId, chat_id: chatId };
    const response = await fetch(endpoint, {
      method: "POST",
      body: JSON.stringify(data),
    });

    if (response.ok) {
      const responseData = await response.json();
      dispatch({
        type: "APPLICATION_HISTORY_SUCCESS",
        payload: { ...responseData, chatId },
      });
    } else {
      console.log("Chat history not found");
    }
  } catch (error) {
    dispatch({
      type: "APPLICATION_HISTORY_FAIL",
      payload: error.response?.data?.message ?? error.message,
    });
  }
};

export const editApplicationTitle =
  (userId, chatId, newTitle) => async (dispatch) => {
    try {
      const endpoint = `${config.petitionUrl}/UpdateApplicationTitle`;
      const response = await fetch(endpoint, {
        method: "POST",
        body: JSON.stringify({
          user_id: userId,
          chat_id: chatId,
          chat_title: newTitle,
        }),
      });
      if (response.ok) {
        const responseData = await response.text();
        console.log(responseData);
        dispatch({
          type: "EDIT_APPLICATION_TITLE",
          payload: { chatId, newTitle },
        });
        console.log("Title updated");
      } else {
        console.log("Title not found");
      }
    } catch (error) {
      console.log(error.response?.data?.message ?? error.message);
    }
  };

export const deleteApplication = (userId, chatId) => async (dispatch) => {
  try {
    const endpoint = `${config.petitionUrl}/DeleteApplication`;
    const response = await fetch(endpoint, {
      method: "POST",
      body: JSON.stringify({ user_id: userId, chat_id: chatId }),
    });
    if (response.ok) {
      dispatch({
        type: "DELETE_APPLICATION_TITLE",
        payload: { chatId },
      });
      console.log("Chat deleted");
    } else {
      console.log("Chat not found");
    }
  } catch (error) {
    console.log(error.response?.data?.message ?? error.message);
  }
};

export const togglePinApplication =
  (userId, chatId, isPinned) => async (dispatch) => {
    try {
      const endpoint = `${config.petitionUrl}/${
        isPinned ? "UnpinApplication" : "PinApplication"
      }`;

      const response = await fetch(endpoint, {
        method: "POST",
        body: JSON.stringify({ user_id: userId, chat_id: chatId }),
      });
      if (response.ok) {
        dispatch({
          type: "TOGGLE_PIN_APPLICATION",
          payload: { chatId, isPinned: !isPinned },
        });
      }
    } catch (error) {
      dispatch({
        type: "GET_CHAT_TITLES_FAIL",
        payload: "Failed to pin/unpin chat",
      });
    }
  };

export const getApplicationTitles = (userId) => async (dispatch, getState) => {
  const state = getState();
  try {
    if (state.applicationTitles.titles?.length === 0) {
      dispatch({
        type: "GET_APPLICATION_TITLES_REQUEST",
      });
    }

    const endpoint = `${config.petitionUrl}/HistoryApplication`;

    const response = await fetch(endpoint, {
      method: "POST",
      body: JSON.stringify({ user_id: userId }),
    });
    if (response.ok) {
      const responseData = await response.json();
      dispatch({
        type: "GET_APPLICATION_TITLES_SUCCESS",
        payload: responseData,
      });
    }
  } catch (error) {
    dispatch({
      type: "GET_APPLICATION_TITLES_FAIL",
      payload:
        "Application history could not be fetched at this moment. Please try again later.",
    });
  }
};

export const saveApplication = (userId, chatId) => async (dispatch) => {
  try {
    const endpoint = `${config.petitionUrl}/SaveApplication`;

    const response = await fetch(endpoint, {
      method: "POST",
      body: JSON.stringify({ user_id: userId, chat_id: chatId }),
    });
    if (response.ok) {
      const responseData = await response.json();
      dispatch({
        type: "UPDATE_IS_SAVED_APPLICATION",
        payload: { chatId },
      });
      return responseData;
    }
  } catch (error) {
    return Promise.reject(error);
  }
};
