import { Box, Typography, Button, CircularProgress } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import SendIcon from "@mui/icons-material/Send";
import { v4 as uuidv4 } from "uuid";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import config from "../../config/api";
import DynamicForm from "../UI/DynamicForm";
import DropdownField from "../UI/DropdownField";
import appDraftingImage from "./../../assets/images/appDrafting.png";
import { getApplicationTitles } from "./../../actions/applicationActions";
import TitleInputModal from "../Modal/TitleInputModal";
import { showInsufficientCreditsModal } from "../../actions/creditActions";
import { deductCredits } from "../../utils/HelperFunction/deductCredits";
import ConfirmationModal from "../Modal/RegenerateConfirmationModal";

const ApplicationForm = ({
  setGeneratedApplication,
  isGenerating,
  setIsGenerating,
  readOnly,
  setReadOnly,
  isEditing,
  setIsEditing,
}) => {
  const dispatch = useDispatch();

  const credits = useSelector((state) => state.credit.credits); // Get credits from Redux store
  const userId = useSelector((state) => state.credit.userId);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const selectedApplication = useSelector((state) => state.selectedApplication);
  const { selectedApp } = selectedApplication;

  const newApplicationRequest = useSelector(
    (state) => state.newApplicationRequest
  );
  const { isClicked } = newApplicationRequest;

  const [selectedApplicationType, setSelectedApplicationType] = useState("");
  const [formConfig, setFormConfig] = useState({});
  const [applicationTypes, setApplicationTypes] = useState([]);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [isLoadingTypes, setIsLoadingTypes] = useState(false);
  const [openTitleInputModal, setOpenTitleInputModal] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [tempChatId, setTempChatId] = useState("");
  const [formData, setFormData] = useState(() => {
    const initialData = {};
    formConfig.fields?.forEach((field) => {
      initialData[field.name] = field.value || "";
    });
    return initialData;
  });

  const getDynamicEndpoint = (selectedApp, applicationTypes) => {
    if (selectedApp && selectedApp.Category) {
      const matchedType = applicationTypes.find(
        (type) => type.Name === selectedApp.Category
      );
      return matchedType ? matchedType.Endpoint : null;
    }
    return null;
  };
  const handleSaveTitleInput = async (title, isConfirmed, editing = false) => {
    if (!title) return;

    if ((!isConfirmed && selectedApp) || editing) {
      setOpenConfirmationModal(true);
      return;
    }
    setOpenConfirmationModal(false);

    let creditsToDeduct = 1;
    if (credits <= 0 || credits <= creditsToDeduct) {
      dispatch(showInsufficientCreditsModal()); // Trigger modal if credits are insufficient
      return;
    }

    setIsGenerating(true);
    setReadOnly(true);
    const newChatId = tempChatId ? tempChatId : uuidv4();
    const currentTime = dayjs().format("DD-MM-YYYY");
    const date = formData.Date;
    let updatedFormData;
    if (date) {
      // Parse the date using dayjs
      const day = dayjs(date).date().toString();
      const year = dayjs(date).year().toString();
      const monthName = dayjs(date).format("MMMM");

      updatedFormData = {
        ...formData,
        user_id: userInfo._id,
        chat_id: selectedApp ? selectedApp?.chat_id : newChatId,
        timecreated: currentTime,
        Category: selectedApp
          ? selectedApp?.Category
          : selectedApplicationType.Name,
        Title: title,
        Day: day,
        Month: monthName,
        Year: year,
      };
    } else {
      updatedFormData = {
        ...formData,
        user_id: userInfo._id,
        chat_id: selectedApp ? selectedApp?.chat_id : newChatId,
        timecreated: currentTime,
        Category: selectedApp
          ? selectedApp?.Category
          : selectedApplicationType.Name,
        Title: title,
      };
    }
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    // Dynamic Endpoint Handling
    let endpoint;
    if (selectedApp) {
      endpoint = `${config.petitionForm}/${getDynamicEndpoint(
        selectedApp,
        applicationTypes
      )}`;
    } else {
      endpoint = `${config.petitionForm}/${selectedApplicationType.Endpoint}`;
    }
    setTempChatId(newChatId);
    try {
      const response = await fetch(endpoint, {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(updatedFormData),
        redirect: "follow",
      });
      if (response.ok) {
        const generatedApplication = await response.json();
        setGeneratedApplication(generatedApplication);
        dispatch({
          type: "SET_SELECTED_APPLICATION",
          payload: {
            ...generatedApplication?.metadata,
            form_metadata: generatedApplication?.form_metadata,
          },
        });
        setIsGenerating(false);
        setReadOnly(true);
        dispatch(getApplicationTitles(userInfo?._id));
        // Deduct credits
        const deductionSuccess = deductCredits(
          {
            userId,
            creditsToDeduct: 1,
            event: "Generate application on application drafting page",
          },
          dispatch
        );

        if (!deductionSuccess) {
          console.warn(
            "Failed to deduct credits. Ensure retry or user notification."
          );
        }
        setReadOnly(true);
        setIsEditing(false);
      } else {
        toast.error(
          "The application could not be saved. Something went wrong."
        );
        setIsGenerating(false);
        setReadOnly(false);
      }
    } catch (error) {
      setIsGenerating(false);
      console.log(error);
    } finally {
      setIsGenerating(false);
    }
  };

  const getApplicationTypes = async () => {
    setIsLoadingTypes(true);
    const endpoint = `${config.petitionForm}/Applications`;
    try {
      const response = await fetch(endpoint, {
        method: "GET",
        redirect: "follow",
      });
      if (response.ok) {
        setIsLoadingTypes(false);
        const applicationTypesData = await response.json();
        // List of Endpoints that are "Not Working"
        const notWorkingEndpoints = [
          "LegalNotice",
          "SuitForDeclarationNadra",
          "SuitForSpecificPerformance",
          "Pre-arrest-bail-two-accused",
          "Guardianship-Application",
          "Order-39-Rule-1-and-2-Application",
        ];

        const updatedApplicationTypes = applicationTypesData?.Applications?.map(
          (item) => {
            const isNotWorking = notWorkingEndpoints.includes(item.Endpoint);
            return {
              ...item,
              status: isNotWorking ? "In Preview" : "Ready",
              color: isNotWorking ? "warning.main" : "success.main", // Use theme colors for Material-UI
              Name: isNotWorking ? `${item.Name}` : item.Name, // Update name for "Not Working"
            };
          }
        );
        // Sort the array to have "Working" items at the top
        const sortedApplicationTypes = updatedApplicationTypes.sort((a, b) => {
          if (a.status === "Ready" && b.status !== "Ready") return -1;
          if (a.status !== "Ready" && b.status === "Ready") return 1;
          return 0;
        });

        setApplicationTypes(sortedApplicationTypes);
      }
    } catch (error) {
      setIsLoadingTypes(false);
      console.log(error);
    }
  };

  const handleLoadTemplate = async (value) => {
    // if (!selectedApplicationType) return;
    setIsFormLoading(true);
    const endpoint = `${config.petitionForm}/${value.Endpoint}`;
    try {
      const response = await fetch(endpoint, {
        method: "GET",
        redirect: "follow",
      });
      if (response.ok) {
        const config = await response.json();
        setFormConfig(config);
        setIsFormLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleRadioChange = (e, fieldName) => {
    setFormData((prevState) => ({
      ...prevState,
      [fieldName]: e.target.value,
    }));
  };

  const validateFields = (fields, formData) => {
    const missingFields = [];

    fields.forEach((field) => {
      if (field.type === "list" && Array.isArray(field.fields)) {
        // Recursively validate nested fields in case of "list"
        const nestedMissing = validateFields(field.fields, formData);
        missingFields.push(...nestedMissing);
      } else if (field.required && !formData[field.name]) {
        // Validate non-list fields
        missingFields.push(field);
      }
    });

    return missingFields;
  };

  const generateApplication = async (regenerate) => {
    // Validate required fields
    const missingFields = validateFields(
      formConfig?.form?.fields || [],
      formData
    );
    if (missingFields?.length > 0) {
      toast.info("Please fill all the required fields.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    } else {
      if (regenerate) {
        setOpenConfirmationModal(true);
      } else {
        setOpenTitleInputModal(true);
      }
    }
  };

  const handleSelectTemplate = (value) => {
    setSelectedApplicationType(value);
    handleLoadTemplate(value);
    setReadOnly(false);
    setGeneratedApplication("");
    setTempChatId("");
    setFormData({});
  };

  useEffect(() => {
    if (isClicked) {
      setSelectedApplicationType("");
      setFormConfig({});
      setFormData({});
      dispatch({ type: "NEW_APPLICATION_RESET" });
      setReadOnly(false);
      setIsEditing(false);
    }
  }, [isClicked, dispatch]);

  useEffect(() => {
    getApplicationTypes();
  }, []);

  useEffect(() => {
    if (selectedApp) {
      setFormConfig({ form: selectedApp?.form_metadata });
      setTempChatId("");
      setReadOnly(true);
    }

    if (selectedApp?.form_metadata) {
      const initialData = {};
      selectedApp?.form_metadata?.fields.forEach((field) => {
        // Use the value from field configuration or default to an empty string
        initialData[field.name] = field.value || "";
      });
      setFormData(initialData);
    }
  }, [selectedApp]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "41%",
        height: "calc(100vh - 50px)",
        bgcolor: "#f5f5f5",
        boxSizing: "border-box",
        p: "12px 0px 0px 12px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          boxSizing: "border-box",
          flexGrow: 1,
          bgcolor: "#fff",
          borderRadius: "8px",
          p: 2,
        }}
      >
        <Typography
          sx={{
            textAlign: "left",
            mb: 2,
            color: "#3C50B1",
            fontSize: "18px",
            fontWeight: "bold",
          }}
        >
          {isEditing
            ? "Edit Template"
            : selectedApp
            ? "Preview Template"
            : "Select Template"}
        </Typography>
        {!selectedApp && (
          <>
            <DropdownField
              label={"Select a Template"}
              selectedValue={selectedApplicationType}
              options={applicationTypes}
              handleChange={handleSelectTemplate}
              isLoading={isLoadingTypes}
            />
          </>
        )}
        {!isFormLoading &&
        selectedApplicationType &&
        Object.keys(selectedApplicationType).length > 0 &&
        Object.keys(formConfig).length !== 0 ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              overflowY: "auto",
              mt: 2,
              paddingRight: "8px",
              "::-webkit-scrollbar": {
                width: "5px",
              },
              "::-webkit-scrollbar-track": {
                background: "#fff",
              },
              "::-webkit-scrollbar-thumb": {
                background: "#bbb",
                borderRadius: "6px",
              },
            }}
          >
            <Typography
              sx={{
                color: "#303f9f",
                mb: 1,
                fontSize: "16px",
              }}
            >
              {selectedApp?.Category || selectedApplicationType?.Name}
            </Typography>

            <DynamicForm
              formConfig={formConfig}
              formData={formData}
              readOnly={readOnly}
              handleInputChange={handleInputChange}
              handleRadioChange={handleRadioChange}
            />

            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                variant="contained"
                color="success"
                endIcon={
                  isGenerating ? (
                    <CircularProgress size={18} sx={{ color: "#fff" }} />
                  ) : (
                    <SendIcon />
                  )
                }
                disabled={isGenerating || readOnly}
                sx={{
                  borderRadius: "8px",
                  mt: 3,
                  textTransform: "none",
                }}
                onClick={() => {
                  generateApplication(isEditing ? true : false);
                }}
              >
                {isEditing ? "Regenerate" : "Generate"}
              </Button>
            </Box>
          </Box>
        ) : isFormLoading ? (
          <CircularProgress
            size={20}
            sx={{
              mt: 2,
              display: "flex",
              alignSelf: "center",
              justifySelf: "center",
            }}
          />
        ) : selectedApp ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              overflowY: "auto",
              mt: 2,
              paddingRight: "8px",
              "::-webkit-scrollbar": {
                width: "5px",
              },
              "::-webkit-scrollbar-track": {
                background: "#fff",
              },
              "::-webkit-scrollbar-thumb": {
                background: "#bbb",
                borderRadius: "6px",
              },
            }}
          >
            <Typography
              sx={{
                color: "#303f9f",
                mb: 1,
                fontSize: "16px",
              }}
            >
              {selectedApp?.Category || selectedApplicationType?.Name}
            </Typography>
            <DynamicForm
              formConfig={formConfig}
              readOnly={readOnly}
              formData={formData}
              handleInputChange={handleInputChange}
              handleRadioChange={handleRadioChange}
            />
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                variant="contained"
                color="success"
                endIcon={
                  isGenerating ? (
                    <CircularProgress size={18} sx={{ color: "#fff" }} />
                  ) : (
                    <SendIcon />
                  )
                }
                disabled={isGenerating || readOnly}
                sx={{
                  borderRadius: "8px",
                  mt: 3,
                  textTransform: "none",
                }}
                onClick={() => {
                  generateApplication(true);
                }}
              >
                Regenerate
              </Button>
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              gap: 1,
            }}
          >
            <img
              width="120px"
              height="auto"
              src={appDraftingImage}
              alt="moduleLogo"
            />
            <Typography sx={{ fontSize: "13px" }}>
              Choose a template to begin drafting your application
            </Typography>
          </Box>
        )}
      </Box>
      <TitleInputModal
        open={openTitleInputModal}
        onClose={() => {
          setOpenTitleInputModal(false);
        }}
        onSave={(title) =>
          handleSaveTitleInput(title, false, isEditing ? true : false)
        }
      />
      <ConfirmationModal
        open={openConfirmationModal}
        onClose={() => setOpenConfirmationModal(false)}
        onConfirm={() => {
          handleSaveTitleInput(
            selectedApp ? selectedApp?.Title : "",
            true,
            false
          );
          setOpenConfirmationModal(false);
          setIsEditing(false);
        }}
        type={"application"}
      />
    </Box>
  );
};

export default ApplicationForm;
