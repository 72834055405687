const config = {
  backendUrl: "https://rg-law-gpt-be-auth2.azurewebsites.net/api",
  apiUrl: "https://rg-law-gpt-openai-functions.azurewebsites.net/api",
  // petitionUrl: 'https://rg-law-gpt-ml-petition-functions.azurewebsites.net/api',
  petitionUrl:
    "https://rg-lawgpt-legal-document-generation.azurewebsites.net/api",
  petitionForm:
    "https://rg-lawgpt-legal-document-generation.azurewebsites.net/api",
  statuteSearchUrl: "https://search-engine-lawgpt.azurewebsites.net/api/search",
  searchUrl:
    "https://search-engine-caselaws-test-pld.azurewebsites.net/api/search",
  testSearchUrl:
    "https://search-engine-caselaws-test-pld.azurewebsites.net/api/search",
  azureCasesIndexUrl:
    "https://rg-law-gpt-ai-search-v4.search.windows.net/indexes/law-cases-detailed-keyword/docs/search?api-version=2024-07-01",
  azureStatutesIndexUrl:
    "https://rg-law-gpt-ai-search-v3.search.windows.net/indexes/legal-chat-statutes-index/docs/search?api-version=2024-07-01",
  apiKeyV3: "unsTowEg7mg8dXgT5RZj01BtxYR49IJwJcUbxx2duzAzSeBKrwIv",
  apiKeyV4: "gLCETv7FncqsUKJapxXsvKn0Il00vJnSjUJLBrb7YaAzSeB11vQb",
  baseURL: "https://dev.platform.lawgpt.pk",
  caseSearchActiveUrl:
    "https://search-engine-caselaws-test-pld.azurewebsites.net/api/search",
  // CaseLawtestURL: 'https://test-results-replica.azurewebsites.net/api/search',
  CaseLawtestURL:
    "https://search-engine-caselaws-test-pld.azurewebsites.net/api/search",
  //PRODUCTION_GA_TRACKING_ID: "G-MLMSMEKZ2K",
  PRODUCTION_GA_TRACKING_ID: "G-MLMSMEKZ2K",
};
//https://search-engine-lawgpt.azurewebsites.net
////'https://search-engine-caselaws-test-pld.azurewebsites.net/api/search',

export default config;
