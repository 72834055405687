import config from "../config/api";
import { deductCredits } from "../utils/HelperFunction/deductCredits";
import { showInsufficientCreditsModal } from "./creditActions";
export const STATUTE_SEARCH_REQUEST = "STATUTE_SEARCH_REQUEST";
export const STATUTE_SEARCH_SUCCESS = "STATUTE_SEARCH_SUCCESS";
export const STATUTE_SEARCH_FAILURE = "STATUTE_SEARCH_FAILURE";
export const STATUTE_SECTIONS_REQUEST = "STATUTE_SECTIONS_REQUEST";
export const STATUTE_SECTIONS_SUCCESS = "STATUTE_SECTIONS_SUCCESS";
export const STATUTE_SECTIONS_FAILURE = "STATUTE_SECTIONS_FAILURE";
let currentAbortController = null; // Maintain a single abort controller
export const statuteSearchResults =
  (userQuery, searchMode, top, skip, filters) => async (dispatch, getState) => {
    try {
      // Abort any ongoing request
      if (currentAbortController) {
        currentAbortController.abort();
      }

      // Create a new AbortController instance
      currentAbortController = new AbortController();
      const { signal } = currentAbortController;

      const state = getState();
      const { credits, userId } = state.credit;
      let creditsToDeduct = 1;
      if (credits <= 0 || credits <= creditsToDeduct) {
        dispatch(showInsufficientCreditsModal());
        return;
      }

      dispatch({
        type: STATUTE_SEARCH_REQUEST,
      });

      const endpoint = `${config.statuteSearchUrl}/statutes`;
      const { year, letter, province, type } = filters;

      let filterString = "";

      if (year) {
        filterString += `Year eq '${year}'`;
      }

      if (letter) {
        const nextLetter = String.fromCharCode(letter.charCodeAt(0) + 1);
        if (filterString) {
          filterString += ` and (Statute_Name ge '${letter}' and Statute_Name lt '${nextLetter}')`;
        } else {
          filterString = `(Statute_Name ge '${letter}' and Statute_Name lt '${nextLetter}')`;
        }
      }

      if (province) {
        filterString += filterString ? " and " : "";
        filterString += `Province eq '${province}'`;
      }

      if (type) {
        filterString += filterString ? " and " : "";
        filterString += `Statute_Type eq '${type}'`;
      }

      const requestBody = {
        query: userQuery,
        sort_by: "",
        filter: filterString || "",
        search_field_mode: searchMode,
        top: top,
        skip: skip,
        count: true,
      };

      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
        signal, // Attach the signal to the request
      });

      if (response.ok) {
        const responseData = await response.json();

        dispatch({
          type: STATUTE_SEARCH_SUCCESS,
          payload: responseData,
        });

        const success = deductCredits(
          { userId, creditsToDeduct, event: "Search by keywords of Statute" },
          dispatch
        );
        if (!success) {
          console.error("Failed to deduct credits after successful API call.");
        }
      } else {
        throw new Error("Failed to fetch statute search results");
      }
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("Request aborted");
      } else {
        dispatch({
          type: STATUTE_SEARCH_FAILURE,
          payload: error.message,
        });
      }
    }
  };

export const searchStatutesByYear = (userQuery, year) => async (dispatch) => {
  try {
    const endpoint = `${config.statuteSearchUrl}/statutes`;

    const requestBody = {
      query: userQuery,
      filter: `Year eq '${year}'`,
      mode: "Title",
      sort_by: "",
      top: 10,
      skip: 0,
      count: true,
    };
    const response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });

    if (response.ok) {
      const responseData = await response.json();

      // Filter out results with the specific text
      // const filteredResults = responseData.value.filter(
      //   (item) => item.Statute !== 'Error Code:700 Sorry Something went wrong'
      // );
      dispatch({
        type: STATUTE_SEARCH_SUCCESS,
        payload: responseData,
      });
    } else {
      throw new Error("Failed to fetch statute search results");
    }
  } catch (error) {
    dispatch({
      type: STATUTE_SEARCH_FAILURE,
      payload: error.message,
    });
  }
};

export const searchStatutesByLetter = (letter) => async (dispatch) => {
  try {
    const nextLetter = String.fromCharCode(letter.charCodeAt(0) + 1);
    const endpoint = `${config.statuteSearchUrl}/statutes`;

    const requestBody = {
      query: "",
      filter: `Statute_Name ge '${letter}' and Statute_Name lt '${nextLetter}'`,
      search_field_mode: "Title",
      sort_by: "",
      top: 10,
      skip: 0,
      count: true,
    };
    const response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });

    if (response.ok) {
      const responseData = await response.json();

      // Filter out results with the specific text
      // const filteredResults = responseData.value.filter(
      //   (item) => item.Statute !== 'Error Code:700 Sorry Something went wrong'
      // );
      dispatch({
        type: STATUTE_SEARCH_SUCCESS,
        payload: responseData,
      });
    } else {
      throw new Error("Failed to fetch statute search results");
    }
  } catch (error) {
    dispatch({
      type: STATUTE_SEARCH_FAILURE,
      payload: error.message,
    });
  }
};

export const applyStatuteFilters =
  (userQuery, filters, searchMode, top = 10, skip = 0) =>
  async (dispatch, getState) => {
    try {
      // Abort any ongoing request
      if (currentAbortController) {
        currentAbortController.abort();
      }

      // Create a new AbortController instance
      currentAbortController = new AbortController();
      const { signal } = currentAbortController;

      const state = getState();
      const { credits, userId } = state.credit;
      let creditsToDeduct = 1;
      if (credits <= 0 || credits <= creditsToDeduct) {
        dispatch(showInsufficientCreditsModal()); // Trigger modal if credits are insufficient
        return;
      }

      dispatch({
        type: STATUTE_SEARCH_REQUEST,
      });

      const { year, letter, province, type } = filters;
      const endpoint = `${config.statuteSearchUrl}/statutes`;

      let filterString = "";

      if (year) {
        filterString += `Year eq '${year}'`;
      }

      if (letter) {
        const nextLetter = String.fromCharCode(letter.charCodeAt(0) + 1);
        if (filterString) {
          filterString += ` and (Statute_Name ge '${letter}' and Statute_Name lt '${nextLetter}')`;
        } else {
          filterString = `(Statute_Name ge '${letter}' and Statute_Name lt '${nextLetter}')`;
        }
      }

      if (province) {
        filterString += filterString ? " and " : "";
        filterString += `Province eq '${province}'`;
      }

      if (type) {
        filterString += filterString ? " and " : "";
        filterString += `Statute_Type eq '${type}'`;
      }

      const requestBody = {
        query: userQuery || "",
        filter: filterString || "",
        search_field_mode: searchMode,
        sort_by: "",
        top: top,
        skip: skip || 0,
        count: true,
      };

      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
        signal, // Attach the signal to the request
      });

      if (response.ok) {
        const responseData = await response.json();

        // Dispatch the success action with the data
        dispatch({
          type: STATUTE_SEARCH_SUCCESS,
          payload: responseData,
        });

        // Deduct credits after successful API call
        const success = deductCredits(
          { userId, creditsToDeduct, event: "Filters of Statute Search" },
          dispatch
        );
        if (!success) {
          console.error("Failed to deduct credits after successful API call.");
        }
      } else {
        throw new Error("Failed to fetch statute search results");
      }
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("Request aborted");
      } else {
        dispatch({
          type: STATUTE_SEARCH_FAILURE,
          payload: error.message,
        });
      }
    }
  };

export const searchStatutesBySection =
  (userQuery, section) => async (dispatch) => {
    try {
      const endpoint = `${config.statuteSearchUrl}/indexes/statutes-index-v5/docs/search?api-version=2024-05-01-preview`;
      const apiKey = "unsTowEg7mg8dXgT5RZj01BtxYR49IJwJcUbxx2duzAzSeBKrwIv";

      const requestBody = {
        facets: [
          "Statute_Name,count:0,sort:value",
          "Year,count:50,sort:-value",
          "Section",
          "Act_Ordinance,sort:count",
        ],
        top: 0,
        searchMode: "any",
        filter: `Section eq '${section}'`,
        queryType: "full",
        search: userQuery,
      };
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "api-key": apiKey,
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        const responseData = await response.json();

        // Filter out results with the specific text
        // const filteredResults = responseData.value.filter(
        //   (item) => item.Statute !== 'Error Code:700 Sorry Something went wrong'
        // );
        dispatch({
          type: STATUTE_SEARCH_SUCCESS,
          payload: responseData,
        });
      } else {
        throw new Error("Failed to fetch statute search results");
      }
    } catch (error) {
      dispatch({
        type: STATUTE_SEARCH_FAILURE,
        payload: error.message,
      });
    }
  };

export const searchSections =
  (userQuery, statuteName, searchMode) => async (dispatch) => {
    try {
      dispatch({
        type: STATUTE_SECTIONS_REQUEST,
      });

      const endpoint = `${config.statuteSearchUrl}/statutes`;

      const requestBody = {
        query: userQuery,
        sort_by: "",
        filter: `(Statute_Name eq '${statuteName}')`,
        search_field_mode: searchMode,
        top: 50,
        skip: 0,
        count: true,
      };
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        const responseData = await response.json();

        // Filter out results with the specific text
        const filteredResults = responseData["search_results"].filter(
          (item) => item.Statute !== "Error Code:700 Sorry Something went wrong"
        );
        dispatch({
          type: STATUTE_SECTIONS_SUCCESS,
          payload: filteredResults,
        });
      } else {
        throw new Error("Failed to fetch statute search results");
      }
    } catch (error) {
      dispatch({
        type: STATUTE_SECTIONS_FAILURE,
        payload: error.message,
      });
    }
  };

export const searchStatutesByActOrdinance =
  (userQuery, actOrdinance) => async (dispatch) => {
    try {
      const endpoint = `${config.statuteSearchUrl}/indexes/statutes-index-v5/docs/search?api-version=2024-05-01-preview`;
      const apiKey = "unsTowEg7mg8dXgT5RZj01BtxYR49IJwJcUbxx2duzAzSeBKrwIv";

      const requestBody = {
        facets: [
          "Statute_Name,count:0,sort:value",
          "Year,count:50,sort:-value",
          "Section",
          "Act_Ordinance",
        ],
        top: 0,
        searchMode: "any",
        filter: `Act_Ordinance eq '${actOrdinance}'`,
        queryType: "full",
        search: userQuery,
      };
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "api-key": apiKey,
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        const responseData = await response.json();

        // Filter out results with the specific text
        // const filteredResults = responseData.value.filter(
        //   (item) => item.Statute !== 'Error Code:700 Sorry Something went wrong'
        // );
        dispatch({
          type: STATUTE_SEARCH_SUCCESS,
          payload: responseData,
        });
      } else {
        throw new Error("Failed to fetch statute search results");
      }
    } catch (error) {
      dispatch({
        type: STATUTE_SEARCH_FAILURE,
        payload: error.message,
      });
    }
  };

export const searchActOrdinance =
  (userQuery, statuteName) => async (dispatch) => {
    try {
      dispatch({
        type: STATUTE_SECTIONS_REQUEST,
      });

      const endpoint = `${config.statuteSearchUrl}/indexes/statutes-index-v5/docs/search?api-version=2024-05-01-preview`;
      const apiKey = "unsTowEg7mg8dXgT5RZj01BtxYR49IJwJcUbxx2duzAzSeBKrwIv";

      const requestBody = {
        facets: [
          "Statute_Name,sort:count",
          "Year,sort:count",
          "Section,sort:count",
          "Act_Ordinance",
        ],
        filter: `(Act_Ordinance eq '${statuteName}')`,
        searchMode: "any",
        queryType: "full",
        search: userQuery,
      };
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "api-key": apiKey,
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        const responseData = await response.json();

        // Filter out results with the specific text
        const filteredResults = responseData.value.filter(
          (item) => item.Statute !== "Error Code:700 Sorry Something went wrong"
        );
        dispatch({
          type: STATUTE_SECTIONS_SUCCESS,
          payload: filteredResults,
        });
      } else {
        throw new Error("Failed to fetch statute search results");
      }
    } catch (error) {
      dispatch({
        type: STATUTE_SECTIONS_FAILURE,
        payload: error.message,
      });
    }
  };
