import axios from "axios";
import config from "../config/api";
import { toast } from "react-toastify";

export const SET_CREDITS = "SET_CREDITS";
export const UPDATE_CREDITS = "UPDATE_CREDITS";
export const REDUCE_CREDITS = "REDUCE_CREDITS";
export const SHOW_INSUFFICIENT_CREDITS_MODAL =
  "SHOW_INSUFFICIENT_CREDITS_MODAL";
export const HIDE_INSUFFICIENT_CREDITS_MODAL =
  "HIDE_INSUFFICIENT_CREDITS_MODAL";
export const HAS_SEEN_CREDITS_MODAL = "HAS_SEEN_CREDITS_MODAL";
export const RESET_CREDITS_STATES = "RESET_CREDITS_STATES";
export const HAS_SEEN_LOW_CREDIT_ALERT = "HAS_SEEN_LOW_CREDIT_ALERT";
export const SHOW_LOW_CREDIT_MODAL = "SHOW_LOW_CREDIT_MODAL";
export const ADD_CREDITS_REQUEST = "ADD_CREDITS_REQUEST";
export const ADD_CREDITS_SUCCESS = "ADD_CREDITS_SUCCESS";
export const ADD_CREDITS_FAIL = "ADD_CREDITS_FAIL";
const baseUrl = process.env.REACT_APP_API_BASE_URL;
export const setCredits = (
  credits,
  userId,
  creditExpiration,
  creditRequestCount
) => ({
  type: SET_CREDITS,
  payload: { credits, userId, creditExpiration, creditRequestCount },
});
export const reduceCredits = (amount) => ({
  type: REDUCE_CREDITS,
  payload: amount,
});

export const showInsufficientCreditsModal = () => ({
  type: SHOW_INSUFFICIENT_CREDITS_MODAL,
});

export const hideInsufficientCreditsModal = () => ({
  type: HIDE_INSUFFICIENT_CREDITS_MODAL,
});

export const hasSeenCreditsModal = (hasSeenModal) => ({
  type: HAS_SEEN_CREDITS_MODAL,
  payload: { hasSeenModal },
});

export const resetCreditState = () => ({
  type: RESET_CREDITS_STATES,
});

export const setHasSeenLowCreditAlert = (hasSeenAlert) => ({
  type: HAS_SEEN_LOW_CREDIT_ALERT,
  payload: hasSeenAlert,
});

export const showLowCreditModal = (show) => ({
  type: SHOW_LOW_CREDIT_MODAL,
  payload: show,
});


export const addCredits = (userId, creditsToAdd) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_CREDITS_REQUEST,
    });

    const headerConfig = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const response = await axios.post(
      `${baseUrl}/addCredits`,
      {
        userId,
        creditsToAdd,
      },
      headerConfig
    );

    const { credits, creditExpiration, creditRequestCount } = response.data;

    // Update Redux store with the updated credits info
    dispatch({
      type: SET_CREDITS,
      payload: {
        credits,
        creditExpiration,
        creditRequestCount,
        userId,
      },
    });

    dispatch({
      type: ADD_CREDITS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: ADD_CREDITS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const requestCredits = (userId) => async (dispatch) => {
  try {
    const { data } = await axios.post(`${baseUrl}/addCredits`, {
      userId, // Pass userId in the request body
    });

    return {
      success: true,
      credits: data.user.credits,
      expirationDate: data.user.creditExpiration,
    };
  } catch (error) { 
    if (error.response && error.response.status === 400) {
      const errorMessage = error.response.data.message || "Request failed.";
      console.log(errorMessage);
      toast.error(errorMessage); // Display error message in toast
    } else {
      console.error("Unexpected error in requestCredits API:", error);
      toast.error("An unexpected error occurred. Please try again.");
    }

    throw error; // Rethrow the error for further handling if needed
  }
};

export const updateCredits = (payload) => ({
  type: "UPDATE_CREDITS",
  payload,
});
