import React, { useState, useRef, useEffect } from "react";
import { Box, Typography, TextField, IconButton, Stack } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import InsightsIcon from "@mui/icons-material/Insights";
import { toast } from "react-toastify";
import Markdown from "react-markdown";
import config from "../../config/api";

const AskChatbot = ({ chatContext, component }) => {
  const [messages, setMessages] = useState([]);
  const [userInput, setUserInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const messageContainerRef = useRef(null);

  // API Call for chatbot response
  const generateResponse = async (userMessage) => {
    const endpoint = `${config.apiUrl}/base_chat`;

    const requestBody = {
      temperature: "precise",
      answer_length: "high",
      query: userMessage,
      context: chatContext,
    };
    try {
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });
      if (!response.ok) {
        throw new Error(`API responded with status ${response.status}`);
      }
      return await response.json();
    } catch (error) {
      console.error("Error fetching chatbot response:", error);
      throw new Error("Failed to generate chatbot response");
    }
  };

  // Function to handle sending messages
  const handleSendMessage = async () => {
    if (userInput.trim() === "") return;

    const userMessage = {
      sender: "User",
      text: userInput,
    };

    setMessages((prevState) => [...prevState, userMessage]);
    setUserInput("");
    setIsLoading(true);

    try {
      const chatbotResponse = await generateResponse(userInput);

      const botResponse = {
        sender: "Bot",
        text: chatbotResponse.response,
      };

      setMessages((prevMessages) => [...prevMessages, botResponse]);
      setIsLoading(false);
    } catch (error) {
      console.error("Error generating chatbot response:", error);
      toast.error("Failed to generate chatbot response. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      handleSendMessage();
    }
  };

  useEffect(() => {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop =
        messageContainerRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <Box
      sx={{
        p: 2,
        pb: "10px",
        boxSizing: "border-box",
        display: "flex",
        width: 316,
        height: "100%",
        overflow: "hidden",
        flexDirection: "column",
      }}
    >
      <Stack
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 1,
          paddingBottom: "4px",
          borderBottom: "1px solid #e6e6e6",
        }}
      >
        <InsightsIcon sx={{ color: "#4CAF50" }} />
        <Typography
          sx={{
            fontWeight: "bold",
            color: "#303f9f",
            fontSize: "14px",
          }}
        >
          {component === "case"
            ? "Find Case Insights"
            : "Find Statute Insights"}
        </Typography>
      </Stack>
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <Box
          ref={messageContainerRef}
          sx={{
            borderRadius: "8px",
            backgroundColor: "#fff",
            padding: 2,
            flex: 1,
            overflowY: "auto",
            border: "1px solid grey",
            mt: 2,
            mb: 2,
            "::-webkit-scrollbar": {
              width: "5px",
            },
            "::-webkit-scrollbar-track": {
              background: "#f1f1f1",
            },
            "::-webkit-scrollbar-thumb": {
              background: "#101936",
              borderRadius: "6px",
            },
          }}
        >
          {messages.length === 0 ? (
            <Typography
              sx={{
                color: "#757575",
                textAlign: "center",
                marginTop: 2,
                fontSize: "14px",
              }}
            >
              {component === "case"
                ? "Ask your case related queries here!"
                : "Ask your statute related queries here!"}
            </Typography>
          ) : (
            <>
              {messages.map((message, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    justifyContent:
                      message.sender === "User" ? "flex-end" : "flex-start",
                    mb: 1,
                  }}
                >
                  <Typography
                    align="left"
                    sx={{
                      backgroundColor:
                        message.sender === "User" ? "#f5f5f5" : "#fff",
                      fontSize: "14px",
                      borderRadius: "8px",
                      border: "1px solid #f1f1f1",
                      padding: "8px 12px",
                      display: "inline-block",
                      wordWrap: "break-word",
                    }}
                  >
                    <Markdown>{message.text}</Markdown>
                  </Typography>
                </Box>
              ))}

              {/* Loading Indicator for Bot */}
              {isLoading && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    mb: 1,
                  }}
                >
                  <Typography
                    align="left"
                    sx={{
                      fontSize: "14px",
                      color: "#000000",
                      borderRadius: "8px",
                      padding: "8px 12px",
                      display: "inline-block",
                      wordWrap: "break-word",
                    }}
                  >
                    <span className="dot-flashing"></span>
                  </Typography>
                </Box>
              )}
            </>
          )}
        </Box>
        {/* Chat Input Field */}
        <Box
          sx={{
            display: "flex",
            gap: 0.5,
            bgcolor: "white",
            justifyContent: "center",
            alignItems: "center",
            mb: "8px",
          }}
        >
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            placeholder="Type your query here..."
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
            onKeyDown={handleEnter}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px",
                fontSize: "12px",
              },
            }}
          />
          <IconButton
            size="small"
            sx={{
              backgroundColor: "#303f9f",
              "&:hover": {
                backgroundColor: "#303f9f",
              },
            }}
            onClick={handleSendMessage}
          >
            <SendIcon fontSize="small" sx={{ color: "#fff" }} />
          </IconButton>
        </Box>
        <Typography
          sx={{
            fontSize: "10px",
            color: "#555",
            width: "100%",

            //textAlign: 'center',
          }}
        >
          Responses are AI-generated. Double-check important details.
        </Typography>
      </Box>
      <style>
        {`
.dot-flashing {
  display: inline-block;
  position: relative;
  width: 8px;
  height: 8px;
  margin-left: 4px;
  border-radius: 50%;
  background-color: #000;
  animation: dot-flashing 1s infinite ease-in-out both;
}

.dot-flashing::before, .dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #000;
}

.dot-flashing::before {
  left: -12px;
  animation: dot-flashing 1s infinite ease-in-out 0.2s both;
}

.dot-flashing::after {
  left: 12px;
  animation: dot-flashing 1s infinite ease-in-out 0.4s both;
}

@keyframes dot-flashing {
  0% {
    opacity: 0.2;
  }
  50%,
  100% {
    opacity: 1;
  }
}
        `}
      </style>
    </Box>
  );
};

export default AskChatbot;
