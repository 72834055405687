import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import debounce from "lodash.debounce";
import {
  Typography,
  Box,
  Button,
  FormControl,
  TextField,
  Autocomplete,
  InputAdornment,
  IconButton,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import {
  applyStatuteFilters,
  statuteSearchResults,
} from "../../actions/statuteSearchActions";

const alphabets = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");

const StatuteSearchSidebar = ({
  selectedLetter,
  updateSelectedYear,
  updateSelectedLetter,
  resultsCount,
  currentPage,
  updateCurrentPage,
  searchMode,
  statuteSearchValue,
  selectedStatuteProvinceFilter,
  setSelectedStatuteProvinceFilter,
  selectedStatuteTypeFilter,
  setSelectedStatuteTypeFilter,
}) => {
  const dispatch = useDispatch();
  const [selectedYear, setSelectedYear] = useState("");
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [yearError, setYearError] = useState("");

  // Debounced API call
  const debouncedApiCall = debounce((value) => {
    // Trigger API request only when year is valid
    dispatch(
      applyStatuteFilters(
        statuteSearchValue,
        {
          year: value,
          letter: selectedLetter,
          province: selectedStatuteProvinceFilter,
          type: selectedStatuteTypeFilter,
        },
        searchMode
      )
    );
  }, 1000);

  const handleYearChange = (event) => {
    const value = event.target.value;

    // Check if input is numeric
    if (/^\d*$/.test(value)) {
      setSelectedYear(value);
      updateSelectedYear(value);
      setYearError("");

      // Check if the input has 4 digits
      if (value.length === 4) {
        debouncedApiCall(value);
        setIsFilterApplied(true);
      } else if (value.length < 4 && value !== "") {
        setYearError("Year must be exactly 4 digits");
      }
    } else {
      setYearError("Please enter a valid numeric year");
    }

    if (!value && isFilterApplied) {
      dispatch(
        applyStatuteFilters("", {
          year: "",
          letter: selectedLetter,
          province: selectedStatuteProvinceFilter,
          type: selectedStatuteTypeFilter,
          searchMode,
        })
      );
    }
  };

  const handleLetterChange = (value) => {
    if (value === null) {
      updateSelectedLetter("");
    } else {
      updateSelectedLetter(value);
      dispatch({ type: "SET_IS_STATUTE_SEARCH_FILTER_APPLIED", payload: true });
      setIsFilterApplied(true);
      updateCurrentPage(1);
      dispatch(
        applyStatuteFilters(
          statuteSearchValue,
          {
            year: selectedYear,
            letter: value,
            province: selectedStatuteProvinceFilter,
            type: selectedStatuteTypeFilter,
          },
          searchMode,
          resultsCount,
          (currentPage - 1) * resultsCount
        )
      );
    }
  };

  const handleClearFilters = () => {
    updateSelectedLetter("");
    setSelectedStatuteProvinceFilter("");
    setSelectedStatuteTypeFilter("");
    setSelectedYear("");
    updateSelectedYear("");
    setIsFilterApplied(false);
    updateCurrentPage(1);
    dispatch({ type: "SET_IS_STATUTE_SEARCH_FILTER_APPLIED", payload: false });
    dispatch(
      statuteSearchResults(
        statuteSearchValue,
        searchMode,
        resultsCount,
        (currentPage - 1) * resultsCount,
        { year: "", letter: "", province: "", type: "" }
      )
    );
  };

  useEffect(() => {
    if (isFilterApplied) {
      dispatch(
        applyStatuteFilters(
          statuteSearchValue,
          {
            year: selectedYear,
            letter: selectedLetter,
            province: selectedStatuteProvinceFilter,
            type: selectedStatuteTypeFilter,
          },
          searchMode,
          resultsCount,
          (currentPage - 1) * resultsCount
        )
      );
    }
  }, [resultsCount, currentPage]);

  const clearYear = () => {
    setSelectedYear("");
    setYearError(""); // Optionally clear error on reset
    updateSelectedYear("");
    dispatch({ type: "SET_IS_STATUTE_SEARCH_FILTER_APPLIED", payload: true });
    setIsFilterApplied(true);
    dispatch(
      applyStatuteFilters(
        statuteSearchValue,
        {
          year: "",
          letter: selectedLetter,
          province: selectedStatuteProvinceFilter,
          type: selectedStatuteTypeFilter,
        },
        searchMode
      )
    );
  };

  const handleTypeChange = (value) => {
    if (value === null) {
      setSelectedStatuteTypeFilter("");
    } else {
      setSelectedStatuteTypeFilter(value);
      dispatch({ type: "SET_IS_STATUTE_SEARCH_FILTER_APPLIED", payload: true });
      setIsFilterApplied(true);
      updateCurrentPage(1);
      dispatch(
        applyStatuteFilters(
          statuteSearchValue,
          {
            year: selectedYear,
            letter: selectedLetter,
            province: selectedStatuteProvinceFilter,
            type: value,
          },
          searchMode,
          resultsCount,
          (currentPage - 1) * resultsCount
        )
      );
    }
  };

  const handleProvinceChange = (value) => {
    if (value === null) {
      setSelectedStatuteProvinceFilter("");
    } else {
      setSelectedStatuteProvinceFilter(value);
      dispatch({ type: "SET_IS_STATUTE_SEARCH_FILTER_APPLIED", payload: true });
      setIsFilterApplied(true);
      updateCurrentPage(1);
      dispatch(
        applyStatuteFilters(
          statuteSearchValue,
          {
            year: selectedYear,
            letter: selectedLetter,
            province: value,
            type: selectedStatuteTypeFilter,
          },
          searchMode,
          resultsCount,
          (currentPage - 1) * resultsCount
        )
      );
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        backgroundColor: "#f5f5f5",
        flex: 1,
        width: "20%",
        height: "calc(100vh - 50px)",
        p: "12px 8px 0 12px",
        overflowY: "auto",
        "::-webkit-scrollbar": {
          width: "5px",
        },
        "::-webkit-scrollbar-track": {
          background: "#fff",
        },
        "::-webkit-scrollbar-thumb": {
          background: "#bbb",
          borderRadius: "6px",
        },
      }}
    >
      <Box
        sx={{
          height: "100%",
          display: "flex",
          boxSizing: "border-box",
          flexDirection: "column",
          bgcolor: "#fff",
          p: "8px 5px 8px 8px",
          borderRadius: "8px",
          flexGrow: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "8px",
            margin: "4px 6px 18px 6px",
            padding: "8px 24px",
            border: "1px solid rgba(0, 0, 0, 0.1)",
            backgroundColor: "#f5f5f5",
          }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "bold",
            }}
          >
            Filters
          </Typography>
        </Box>
        {((selectedLetter && isFilterApplied) ||
          (selectedYear && isFilterApplied) ||
          (selectedStatuteProvinceFilter && isFilterApplied) ||
          (selectedStatuteTypeFilter && isFilterApplied)) && (
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              size="small"
              color="error"
              sx={{ textTransform: "none" }}
              onClick={handleClearFilters}
            >
              Clear filter(s)
            </Button>
          </Box>
        )}
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: 600,
              color: "#333",
              ml: "5px",
            }}
          >
            Letter
          </Typography>
          <FormControl fullWidth>
            <Autocomplete
              size="small"
              value={selectedLetter || ""}
              onChange={(event, newValue) => {
                handleLetterChange(newValue);
              }}
              onInputChange={(e, newValue) => {
                if (!newValue && isFilterApplied) {
                  dispatch(
                    statuteSearchResults(
                      statuteSearchValue,
                      searchMode,
                      resultsCount,
                      (currentPage - 1) * resultsCount,
                      {
                        year: selectedYear,
                        letter: "",
                        province: selectedStatuteProvinceFilter,
                        type: selectedStatuteTypeFilter,
                      }
                    )
                  );
                }
              }}
              disableClearable={!selectedLetter}
              clearIcon={selectedLetter ? <ClearIcon fontSize="small" /> : null}
              options={alphabets}
              getOptionLabel={(option) => option || ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Select Letter"
                  sx={{
                    width: "95%",
                    ml: "5px",
                    mb: "16px",
                    "& .MuiInputBase-input": {
                      fontSize: "13px",
                    },
                    "& .MuiInputBase-input::placeholder": {
                      fontSize: "12px",
                    },
                  }}
                />
              )}
              componentsProps={{
                paper: {
                  sx: {
                    "& .MuiAutocomplete-listbox": {
                      fontSize: "13px",
                      height: "180px",
                    },
                  },
                },
              }}
            />
          </FormControl>
        </Box>
        <Box>
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: 600,
              color: "#333",
              ml: "5px",
            }}
          >
            Year
          </Typography>
          <FormControl fullWidth variant="outlined">
            <TextField
              size="small"
              fullWidth
              placeholder="Enter Year"
              variant="outlined"
              value={selectedYear}
              error={!!yearError}
              helperText={yearError ? yearError : ""}
              onChange={(e) => handleYearChange(e)}
              sx={{
                width: "95%",
                ml: "5px",
                mb: "16px",
                "& .MuiInputBase-input": {
                  fontSize: "13px",
                },
                "& .MuiInputBase-input::placeholder": {
                  fontSize: "12px",
                },
              }}
              InputProps={{
                endAdornment: selectedYear && (
                  <InputAdornment position="end">
                    <IconButton size="small" onClick={clearYear}>
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: 600,
              color: "#333",
              ml: "5px",
            }}
          >
            Type
          </Typography>
          <FormControl fullWidth>
            <Autocomplete
              size="small"
              value={selectedStatuteTypeFilter || ""}
              onChange={(event, newValue) => {
                handleTypeChange(newValue);
              }}
              onInputChange={(e, newValue) => {
                if (!newValue && isFilterApplied) {
                  dispatch(
                    statuteSearchResults(
                      statuteSearchValue,
                      searchMode,
                      resultsCount,
                      (currentPage - 1) * resultsCount,
                      {
                        year: selectedYear,
                        letter: selectedLetter,
                        province: selectedStatuteProvinceFilter,
                        type: "",
                      }
                    )
                  );
                }
              }}
              disableClearable={!selectedStatuteTypeFilter}
              clearIcon={
                selectedStatuteTypeFilter ? (
                  <ClearIcon fontSize="small" />
                ) : null
              }
              options={[
                "Act",
                "Agreement",
                "Amendments",
                "Bills",
                "Code",
                "Constitution",
                "Convention",
                "Law",
                "Order",
                "Ordinance",
                "Policy",
                "Regulation",
                "Resolution",
                "Rule",
              ]}
              getOptionLabel={(option) => option || ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Select type"
                  sx={{
                    width: "95%",
                    ml: "5px",
                    mb: "16px",
                    "& .MuiInputBase-input": {
                      fontSize: "13px",
                    },
                    "& .MuiInputBase-input::placeholder": {
                      fontSize: "12px",
                    },
                  }}
                />
              )}
              componentsProps={{
                paper: {
                  sx: {
                    "& .MuiAutocomplete-listbox": {
                      fontSize: "13px",
                      height: "180px",
                    },
                  },
                },
              }}
            />
          </FormControl>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: 600,
              color: "#333",
              ml: "5px",
            }}
          >
            Province
          </Typography>
          <FormControl fullWidth>
            <Autocomplete
              size="small"
              value={selectedStatuteProvinceFilter || ""}
              onChange={(event, newValue) => {
                handleProvinceChange(newValue);
              }}
              onInputChange={(e, newValue) => {
                if (!newValue && isFilterApplied) {
                  dispatch(
                    statuteSearchResults(
                      statuteSearchValue,
                      searchMode,
                      resultsCount,
                      (currentPage - 1) * resultsCount,
                      {
                        year: selectedYear,
                        letter: selectedLetter,
                        province: "",
                        type: selectedStatuteTypeFilter,
                      }
                    )
                  );
                }
              }}
              disableClearable={!selectedStatuteProvinceFilter}
              clearIcon={
                selectedStatuteProvinceFilter ? (
                  <ClearIcon fontSize="small" />
                ) : null
              }
              options={[
                "Federal",
                "Punjab",
                "Sindh",
                "Balochistan",
                "Khyber Pakhtunkhwa",
                "Gilgit Baltistan",
                "Azad Kashmir And Jammu",
                "Other",
              ]}
              getOptionLabel={(option) => option || ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Select Province"
                  sx={{
                    width: "95%",
                    ml: "5px",
                    mb: "16px",
                    "& .MuiInputBase-input": {
                      fontSize: "13px",
                    },
                    "& .MuiInputBase-input::placeholder": {
                      fontSize: "12px",
                    },
                  }}
                />
              )}
              componentsProps={{
                paper: {
                  sx: {
                    "& .MuiAutocomplete-listbox": {
                      fontSize: "13px",
                      height: "180px",
                    },
                  },
                },
              }}
            />
          </FormControl>
        </Box>
      </Box>
    </Box>
  );
};

export default StatuteSearchSidebar;
