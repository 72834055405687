import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Typography,
  Grid,
  Checkbox,
  Card,
  TextField,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  InputAdornment,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowLeft } from "@fortawesome/free-solid-svg-icons";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
const formatCourtName = (courtName) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(courtName, "text/html");

  const formatTextContent = (name) => {
    return name
      .toLowerCase()
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const walkNodes = (node) => {
    node.childNodes.forEach((child) => {
      if (child.nodeType === Node.TEXT_NODE) {
        child.nodeValue = formatTextContent(child.nodeValue);
      } else if (child.nodeType === Node.ELEMENT_NODE) {
        walkNodes(child);
      }
    });
  };

  walkNodes(doc.body);

  return doc.body.innerHTML;
};

const formatJudgeName = (names) => {
  if (!names || names.length === 0) return "";

  // Check if names is an array, if not treat it as a single name
  if (!Array.isArray(names)) {
    names = [names];
  }

  // Convert each name to title case and join them with commas
  const titleCaseNames = names
    .map((name) =>
      name
        .toLowerCase()
        .split(" ")
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(" ")
    )
    .join(", ");

  return titleCaseNames;
};

const formatCaseTitle = (title) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(title, "text/html");

  const formatTextContent = (text) => {
    return text
      .split("Honorable")[0]
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  const walkNodes = (node) => {
    node.childNodes.forEach((child) => {
      if (child.nodeType === Node.TEXT_NODE) {
        child.nodeValue = formatTextContent(child.nodeValue);
      } else if (child.nodeType === Node.ELEMENT_NODE) {
        walkNodes(child);
      }
    });
  };

  walkNodes(doc.body);

  return doc.body.innerHTML;
};

const CaseDetail = ({ closeOnBack }) => {
  const caseDetail = useSelector((state) => state.caseDetail);
  const { caseItem } = caseDetail;

  const [checkedItems, setCheckedItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [highlightedElements, setHighlightedElements] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [totalMatches, setTotalMatches] = React.useState(0);
  const [currentMatch, setCurrentMatch] = React.useState(0);
  const [highlightedHtml, setHighlightedHtml] = React.useState("");
  const checkboxItems = [
    {
      label: "Facts",
      color: "#ffec94",
      value: "facts",
      available: caseItem?.facts?.length > 0,
    },
    {
      label: "Issues",
      color: "#ffaeae",
      value: "issues",
      available: caseItem?.issues?.length > 0,
    },
    {
      label: "Petitioner's Arguments",
      color: "#ccffbb",
      value: "argument_by_petitioner",
      available: caseItem?.argument_by_petitioner?.length > 0,
    },
    {
      label: "Respondent's Arguments",
      color: "#cccc99",
      value: "argument_by_responses",
      available: caseItem?.argument_by_responses?.length > 0,
    },
    {
      label: "Analysis of the Law",
      color: "#56baec",
      value: "section_analysis",
      available: caseItem?.section_analysis?.length > 0,
    },
    {
      label: "Precedent Analysis",
      color: "#b4d8e7",
      value: "precedent_analysis",
      available: caseItem?.precedent_analysis?.length > 0,
    },
    {
      label: "Court's Reasoning",
      color: "#fce6e6",
      value: "courts_reasoning",
      available: caseItem?.courts_reasoning?.length > 0,
    },
    {
      label: "Conclusion",
      color: "#c0bcb6",
      value: "conclusion",
      available: caseItem?.conclusion?.length > 0,
    },
  ];

  useEffect(() => {
    const highlights = document.querySelectorAll(".highlight");
    setHighlightedElements(highlights);

    if (highlights.length > 0) {
      highlights.forEach((el, index) => {
        if (index === currentIndex) {
          el.style.backgroundColor = "orange";
          el.scrollIntoView({ behavior: "smooth", block: "center" });
        } else {
          el.style.backgroundColor = "yellow";
        }
      });
    }

    // Cleanup function
    return () => {};
  }, [searchTerm, currentIndex]);

  const handleNextMatch = () => {
    if (highlightedElements.length > 0) {
      const nextIndex = (currentIndex + 1) % highlightedElements.length;
      setCurrentIndex(nextIndex);
    }
  };

  const handleCheckboxChange = (value) => {
    setCheckedItems((prev) => {
      // Check if the value is already in the array
      const isValueIncluded = prev?.includes(value);

      // If included, remove it; otherwise, add it
      const updatedItems = isValueIncluded
        ? prev?.filter((item) => item !== value) // Remove the value
        : [...prev, value]; // Add the value

      return updatedItems;
    });
  };

  const highlightText = (html, search) => {
    if (!search) return { html, matchCount: 0 };

    // Escape special characters in the search term
    const escapedTerm = search.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    const regex = new RegExp(
      `(${escapedTerm.replace(/\s+/g, "(\\s|<[^>]+>|\\n|\\r)*")})`,
      "gi"
    );
    let matchCount = 0;
    // Function to highlight text recursively
    const highlightNode = (node) => {
      if (node.nodeType === Node.TEXT_NODE) {
        const highlightedText = node.textContent.replace(regex, (match) => {
          matchCount++;
          return `<span class="highlight" data-index="${matchCount}">${match}</span>`;
        });
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = highlightedText;
        return tempDiv.innerHTML;
      }

      if (node.nodeType === Node.ELEMENT_NODE) {
        const children = Array.from(node.childNodes)
          .map(highlightNode)
          .join("");
        const element = document.createElement(node.nodeName.toLowerCase());
        Array.from(node.attributes).forEach((attr) => {
          element.setAttribute(attr.name, attr.value);
        });
        element.innerHTML = children;
        return element.outerHTML;
      }

      return "";
    };

    // Parse the input HTML and highlight matches
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    const highlightedHtml = Array.from(tempDiv.childNodes)
      .map(highlightNode)
      .join("");

    return { html: highlightedHtml, matchCount };
  };

  const getContentAfterHeadings = (html) => {
    // Parse the HTML string to a DOM structure
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");

    // Find elements that could contain "ORDER" or "JUDGEMENT"
    const allElements = Array.from(doc.querySelectorAll("h1, h2, p, b"));

    // Look for "ORDER" or "JUDGEMENT" inside these elements (including nested elements)
    const targetElement = allElements.find(
      (el) =>
        el.textContent.trim().includes("ORDER") ||
        el.textContent.trim().includes("JUDGEMENT") ||
        el.textContent.trim().includes("JUDGMENT")
    );

    // Create an array to store the content after "ORDER" or "JUDGEMENT"
    let contentAfterHeadings = [];

    if (targetElement) {
      // Traverse the sibling elements after the found target element
      let nextElement =
        targetElement.closest("p, h1, h2, b").nextElementSibling;
      while (nextElement) {
        contentAfterHeadings.push(nextElement.outerHTML);
        nextElement = nextElement.nextElementSibling;
      }
    }

    // Join the content and return
    return contentAfterHeadings.join("");
  };

  const highlightParatagsText = (text, searchTerm) => {
    if (!searchTerm) {
      return { html: text, matchCount: 0 };
    }

    const regex = new RegExp(`(${searchTerm})`, "gi");
    let matchCount = 0;

    const html = text.replace(regex, (match) => {
      matchCount++;
      return `<span style="background-color: yellow; font-weight: bold;">${match}</span>`;
    });

    return { html, matchCount };
  };

  React.useEffect(() => {
    let styledSections = "";
    let totalMatches = 0;

    if (checkedItems?.length > 0) {
      // Generate content with dynamic styles for checked items
      styledSections = checkedItems
        .map((key) => {
          const tagItem = checkboxItems.find((item) => item.value === key);
          if (!tagItem || !caseItem[key]) return "";

          const sectionContent = caseItem[key]
            .map((item) => {
              const highlighted = highlightParatagsText(item, searchTerm);
              totalMatches += highlighted.matchCount; // Count matches for each section
              return `<div>${highlighted.html}</div>`;
            })
            .join("");

          return `
            <div style="margin-top: 12px;">
              <div style="background-color: ${tagItem.color}; font-size: 14px; font-weight: bold; padding-left: 5px;">
                ${tagItem.label}
              </div>
              <div>${sectionContent}</div>
            </div>
          `;
        })
        .join("");
    } else {
      // Generate content for the default case
      const result = highlightText(
        getContentAfterHeadings(
          caseItem?.Case_Description_HTML ||
            caseItem?.metadata.Case_Description_HTML
        ),
        searchTerm
      );
      styledSections = `
        <div class="default-content">
          ${result.html}
        </div>
      `;
      totalMatches = result.matchCount;
    }

    const styledHtml = `
      <style>
        .MsoNormal {
          font-family: 'Roboto', Helvetica, Arial, sans-serif !important;
          font-size: 14px;
          text-align: justify;
        }
        .MsoNormal b {
          color: #303f9f;
        }
        .highlight-section {
          background-color: yellow;
          font-weight: bold;
        }
      </style>
      ${styledSections}
    `;
    // Set the generated HTML and match data
    setHighlightedHtml(styledHtml);
    setTotalMatches(totalMatches);
    setCurrentMatch(totalMatches > 0 ? 1 : 0);
  }, [searchTerm, caseItem, checkedItems]);

  const caseMetaData = [
    {
      label: "Title",
      value: formatCaseTitle(caseItem?.Title || caseItem?.metadata.Title),
    },
    {
      label: "Citation",
      value: caseItem?.Citation_Name || caseItem?.metadata.Citation_Name,
    },
    {
      label: "Court",
      value: formatCourtName(caseItem?.Court || caseItem?.metadata.Court),
    },
    {
      label: "Judge",
      value: formatJudgeName(
        caseItem?.Judge_Name || caseItem?.metadata?.Judge_Name
      ),
    },
    {
      label: "Appellant",
      value: formatCaseTitle(caseItem?.Title).split("Versus")[0],
    },
    {
      label: "Respondents",
      value: formatCaseTitle(caseItem?.Title).split("Versus")[1],
    },
  ];

  React.useEffect(() => {
    if (currentMatch > 0) {
      const element = document.querySelector(
        `.highlight[data-index="${currentMatch}"]`
      );
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "center" });
        element.style.border = "1px solid #303f9f"; // Optional: Visual indication
      }
    }
  }, [currentMatch]);

  const filteredMetaData = caseMetaData.filter((detail) => detail.value);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        flex: 5,
        flexGrow: 1,
        p: "0px 8px",
        boxSizing: "border-box",
        bgcolor: "#f5f5f5",
      }}
    >
      <React.Fragment>
        <Box
          sx={{
            margin: "0 8px",
            backgroundColor: "#f5f5f5",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              bgcolor: "#f5f5f5",
              margin: "16px 0px",
            }}
          >
            <Box sx={{ display: "flex", mr: "6px" }}>
              <IconButton sx={{ color: "#303f9f" }} onClick={closeOnBack}>
                <FontAwesomeIcon icon={faCircleArrowLeft} fontSize="23px" />
              </IconButton>
            </Box>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              sx={{
                backgroundColor: "white",
                //margin: '16px 2px 8px 2px',
                borderRadius: "24px",
                "& .MuiInputBase-root": {
                  height: "40px",
                  fontSize: "14px",
                },
                "& .MuiOutlinedInput-root": {
                  borderRadius: "24px",
                },
                "& input": {
                  padding: "10px 10px",
                  fontSize: "0.875rem",
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: "#303f9f" }} />
                  </InputAdornment>
                ),
                endAdornment: searchTerm && (
                  <InputAdornment position="end">
                    <Typography variant="body2" sx={{ mr: 1 }}>
                      {currentMatch}/{totalMatches}
                    </Typography>
                    <IconButton
                      size="small"
                      onClick={() =>
                        setCurrentMatch((prev) =>
                          prev > 1 ? prev - 1 : totalMatches
                        )
                      }
                      disabled={totalMatches === 0}
                    >
                      <KeyboardArrowUpIcon
                        fontSize="small"
                        sx={{ color: "#303f9f" }}
                      />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() =>
                        setCurrentMatch((prev) =>
                          prev < totalMatches ? prev + 1 : 1
                        )
                      }
                      disabled={totalMatches === 0}
                    >
                      <KeyboardArrowDownIcon
                        fontSize="small"
                        sx={{ color: "#303f9f" }}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          {checkboxItems?.length > 0 && (
            <Card
              sx={{
                overflow: "visible",
                display: "flex",
                flexDirection: "column",
                p: "8px",
                //mt: 1,
                bgcolor: "#fff",
                boxShadow: "1px 2px 6px 0px #00000020",
                borderRadius: "8px",
              }}
            >
              <Typography
                variant="caption"
                sx={{
                  display: "flex",
                  fontWeight: "bold",
                  marginBottom: "6px",
                }}
              >
                Select the following parts of the judgement
              </Typography>
              <Grid container spacing={2}>
                {checkboxItems.map(({ label, color, value, available }) => (
                  <Grid item xs={3} key={label}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        bgcolor: color,
                        p: "5px",
                        borderRadius: "4px",
                      }}
                    >
                      <Checkbox
                        size="small"
                        disabled={!available}
                        checked={checkedItems?.includes(value) || false}
                        onChange={() => handleCheckboxChange(value)}
                        sx={{
                          p: 0,
                        }}
                      />
                      <Typography
                        sx={{
                          color: "#000",
                          ml: "4px",
                          fontWeight: "bold",
                          fontSize: "13px",
                        }}
                      >
                        {label}
                      </Typography>
                      {!available && (
                        <Tooltip
                          title={`No ${label} available for this case`}
                          arrow
                        >
                          <InfoOutlinedIcon
                            sx={{ fontSize: "16px", ml: "4px", color: "#888" }}
                          />
                        </Tooltip>
                      )}
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Card>
          )}
        </Box>
        <Box
          sx={{
            overflowY: "auto",
            bgcolor: "#fff",
            p: "12px 12px",
            m: "16px 8px 8px 8px",
            borderRadius: "8px",
            "::-webkit-scrollbar": {
              width: "5px",
            },
            "::-webkit-scrollbar-track": {
              background: "#f1f1f1",
            },
            "::-webkit-scrollbar-thumb": {
              background: "#bbb",
              borderRadius: "6px",
            },
          }}
        >
          {filteredMetaData.map((detail, index) => (
            <Box
              key={index}
              sx={{
                bgcolor: index % 2 === 0 ? "#f5f5f5" : "#fff",
                paddingLeft: "5px",
                marginBottom: "8px",
              }}
            >
              <Typography sx={{ fontSize: "14px" }}>
                <span style={{ fontWeight: "bold" }}>{detail.label}:</span>{" "}
                <span>{detail.value}</span>
              </Typography>
            </Box>
          ))}
          {checkedItems?.length === 0 ? (
            <Box
              dangerouslySetInnerHTML={{
                __html: highlightedHtml,
              }}
            />
          ) : (
            <div>
              {checkedItems.map((key) => {
                const tagItem = checkboxItems.find(
                  (item) => item.value === key
                );
                if (!tagItem || !caseItem[key]) return null;

                return (
                  <div key={key} style={{ marginTop: "12px" }}>
                    <Typography
                      sx={{
                        backgroundColor: tagItem.color, // Use the color of the respective tag
                        fontSize: "14px",
                        fontWeight: "bold",
                        paddingLeft: "5px",
                      }}
                    >
                      {tagItem.label}
                    </Typography>
                    <List>
                      {caseItem[key].map((item, index) => {
                        const highlighted = highlightText(item, searchTerm); // Highlight the matching text

                        return (
                          <ListItem key={index}>
                            <ListItemText
                              primaryTypographyProps={{
                                fontSize: "14px",
                                backgroundColor: tagItem.color, // Preserve the background color
                              }}
                              // Use `dangerouslySetInnerHTML` for the highlighted text
                              primary={
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: highlighted.html,
                                  }}
                                />
                              }
                            />
                          </ListItem>
                        );
                      })}
                    </List>
                  </div>
                );
              })}
            </div>
          )}
        </Box>
      </React.Fragment>
    </Box>
  );
};

export default CaseDetail;
