import React, { useState } from "react";
import { Box } from "@mui/material";
import CaseSummarySidebar from "./layout/CaseSummarySidebar";
import CaseSummary from "./CaseSummary";

const CaseSummaryDialog = ({ open, onClose }) => {
  const [selectedSummarySection, setSelectedSummarySection] =
    useState("Case Summary");
  if (!open) return null; // Return null if dialog is not open
  return (
    <Box
      sx={{
        position: "absolute",
        width: "calc(100% - 45px)",
        height: "calc(100vh - 50px)",
        top: "50px",
        left: "45px",
        //inset: 0, // Covers the entire viewport
        backgroundColor: "rgba(0, 0, 0, 0.5)", // Optional backdrop effect
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 10, // Higher z-index to overlay on top of other elements
      }}
      onClick={onClose} // Close dialog when clicking on the backdrop
    >
      {/* Content container */}
      <Box
        onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the content
        sx={{
          position: "relative",
          width: "calc(100vw - 45px)",
          height: "calc(100vh - 50px)",
          backgroundColor: "white",
          display: "flex",
          flexDirection: "row",
          overflowY: "clip",
        }}
      >
        <CaseSummarySidebar
          onSectionClick={setSelectedSummarySection}
          selectedSection={selectedSummarySection}
          closeOnBack={onClose}
        />
        <CaseSummary selectedSection={selectedSummarySection} />
      </Box>
    </Box>
  );
};

export default CaseSummaryDialog;
