import config from "../config/api";
import { deductCredits } from "../utils/HelperFunction/deductCredits";
import { showInsufficientCreditsModal } from "./creditActions";
const baseUrl = process.env.REACT_APP_API_BASE_URL;
export const getFacets = () => async (dispatch) => {
  try {
    dispatch({
      type: "SEARCH_FACETS_REQUEST",
    });
    const endpoint =
      "https://facets-lawcases.azurewebsites.net/api/facets/lawcases?";
    const requestBody = {
      facets: [
        "Court,count:0,sort:value",
        "Year,count:0,sort:value",
        "Section,count:0,sort:value",
        "Lawyer_Name,count:0,sort:count",
        "Judge_Name,count:0,sort:count",
      ],
      query: "*",
      filter: "",
      mode: "keyword",
      sort_by: "",
      top: 10,
      skip: 0,
      count: true,
    };
    const response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      //body: JSON.stringify(requestBody),
    });
    if (response.ok) {
      const responseData = await response.json();
      dispatch({
        type: "SEARCH_FACETS_SUCCESS",
        payload: responseData,
      });
    }
  } catch (error) {
    console.log(error);
    dispatch({
      type: "SEARCH_FACETS_FAIL",
      payload: "Failed to fetch facets.",
    });
  }
};

// const collectionMappings = {
//   Lawyer: "Lawyer_Name",
//   Judge: "Judge_Name",
//   Court: "Court",
//   Journal: "Section",
// };

// const constructFilterQuery = (
//   searchTerm,
//   collectionName,
//   selectedValues = {}
// ) => {
//   // Build the search part of the query
//   const searchQuery = `search.ismatch('${searchTerm}*', '${collectionName}')`;

//   // Build the equality filter part by iterating over selectedValues
//   const filterConditions = Object.entries(selectedValues)
//     .filter(([key, value]) => {
//       const fieldName = collectionMappings[key];
//       // Exclude the collection already used in search
//       // Exclude undefined or empty values
//       return fieldName && fieldName !== collectionName && value && value !== "";
//     })
//     .map(([key, value]) => {
//       const fieldName = collectionMappings[key];
//       const filterValue = typeof value === "object" ? value.original : value;
//       return `search.ismatch('${filterValue}', '${fieldName}')`;
//     });

//   // Combine the search query with any additional filter conditions
//   const finalFilterQuery = `filter=${encodeURIComponent(
//     [searchQuery, ...filterConditions].join(" and ")
//   )}`;

//   return finalFilterQuery;
// };

// // Modify the fetch function to use the dynamic filter query
// export const fetchOptions =
//   (searchTerm, collectionName, selectedValues) => async (dispatch) => {
//     const filterQuery = constructFilterQuery(
//       searchTerm,
//       collectionName,
//       selectedValues
//     );
//     const endpoint = `https://facets-lawcases.azurewebsites.net/api/facets/lawcases?${filterQuery}`;

//     dispatch({ type: "FETCH_OPTIONS_REQUEST" });

//     try {
//       const response = await fetch(endpoint, {
//         method: "GET",
//         headers: {
//           "Content-Type": "application/json",
//         },
//       });

//       if (response.ok) {
//         const responseData = await response.json();
//         dispatch({
//           type: "GET_FACETS_RESULTS_SUCCESS",
//           payload: responseData,
//         });
//       }
//     } catch (error) {
//       console.log(error);
//       // dispatch({ type: 'FETCH_OPTIONS_FAIL', error: error.message });
//     }
//   };

// export const fetchLawyerOptions = (searchTerm, collection_name) => async (dispatch) => {
//   const filterQuery = `search.ismatch('${searchTerm}*', '${collection_name}')`;
//   const endpoint = `https://facets-lawcases.azurewebsites.net/api/facets/lawcases?filter=${encodeURIComponent(filterQuery)}`;

//   dispatch({ type: 'FETCH_LAWYER_OPTIONS_REQUEST' });

//   try {
//     const response = await fetch(endpoint, {
//       method: 'GET',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//     });
//     if (response.ok) {
//       const responseData = await response.json();

//       dispatch({
//         type: 'GET_FACETS_RESULTS_SUCCESS',
//         payload: responseData,
//       });

//     }
//   } catch (error) {
//     console.log(error);
//     // dispatch({ type: 'FETCH_LAWYER_OPTIONS_FAIL', error: error.message });
//   }
// };

const collectionMappings = {
  Lawyer: "Lawyer_Name",
  Judge: "Judge_Name",
  Court: "Court",
  Journal: "Section",
};

const constructFilterQuery = (
  searchTerm,
  collectionName,
  selectedValues = {}
) => {
  let searchQuery = "";

  // Only construct `search.ismatch` if collectionName is not Year
  if (collectionName !== "Year") {
    searchQuery = `search.ismatch('${searchTerm}*', '${collectionName}')`;
  }

  // Build the equality filter part by iterating over selectedValues
  const filterConditions = Object.entries(selectedValues)
    .filter(([key, value]) => {
      const fieldName = collectionMappings[key];
      // Exclude the collection already used in search
      // Exclude undefined or empty values
      return fieldName && fieldName !== collectionName && value && value !== "";
    })
    .map(([key, value]) => {
      const fieldName = collectionMappings[key];
      const filterValue = typeof value === "object" ? value.original : value;
      return `search.ismatch('${filterValue}', '${fieldName}')`;
    });

  // Add year range condition if startYear and endYear are present
  if (searchTerm?.startYear && searchTerm?.endYear) {
    if (searchTerm?.startYear === searchTerm?.endYear) {
      const yearCondition = `(Year eq '${searchTerm.startYear}')`;
      filterConditions.push(yearCondition);
    } else {
      const yearCondition = `(Year ge '${searchTerm.startYear}') and (Year le '${searchTerm.endYear}')`;
      filterConditions.push(yearCondition);
    }
  }

  // Combine the search query (if exists) with any additional filter conditions
  const allConditions = [searchQuery, ...filterConditions].filter(Boolean); // Remove empty strings

  const finalFilterQuery = `filter=${encodeURIComponent(
    allConditions.join(" and ")
  )}`;

  return finalFilterQuery;
};

// Modify the fetch function to use the dynamic filter query
export const fetchOptions =
  (searchTerm, collectionName, selectedValues) => async (dispatch) => {
    const filterQuery = constructFilterQuery(
      searchTerm,
      collectionName,
      selectedValues
    );
    const endpoint = `https://facets-lawcases.azurewebsites.net/api/facets/lawcases?${filterQuery}`;

    dispatch({ type: "FETCH_OPTIONS_REQUEST" });

    try {
      const response = await fetch(endpoint, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        dispatch({
          type: "GET_FACETS_RESULTS_SUCCESS",
          payload: responseData,
        });
      }
    } catch (error) {
      console.log(error);
      // dispatch({ type: 'FETCH_OPTIONS_FAIL', error: error.message });
    }
  };

export const searchResults =
  (userQuery, skip = 0, top = 10, mode = "keyword", sortBy = "Most Relevant") =>
  async (dispatch) => {
    try {
      dispatch({
        type: "GET_SEARCH_RESULTS_REQUEST",
      });
      const endpoint = `${config.caseSearchActiveUrl}/lawcases`;
      const requestBody = {
        query: userQuery,
        mode: mode,
        top: top,
        skip: skip,
        filter: "",
        sort_by: sortBy,
        //searchMode: 'all',
      };
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });
      if (response.ok) {
        const responseData = await response.json();
        dispatch({
          type: "GET_SEARCH_RESULTS_SUCCESS",
          payload: responseData,
        });
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: "GET_SEARCH_RESULTS_FAIL",
        payload: "Failed to fetch chat titles.",
      });
    }
  };

export const advancedSearchResults =
  (userQuery) => async (dispatch, getState) => {
    try {
      const state = getState();
      const { credits, userId } = state.credit; // Replace `state.user` with the correct slice for user details
      let creditsToDeduct = 1;
      if (credits <= 0 || credits <= creditsToDeduct) {
        dispatch(showInsufficientCreditsModal()); // Trigger modal if credits are insufficient
        return;
      }
      dispatch({
        type: "GET_SEARCH_RESULTS_REQUEST",
      });
      const endpoint = `${config.caseSearchActiveUrl}/lawcases`;
      const requestBody = {
        query: userQuery,
      };
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        const responseData = await response.json();
        dispatch({
          type: "GET_SEARCH_RESULTS_SUCCESS",
          payload: responseData,
        });
        const success = deductCredits(
          { userId, creditsToDeduct, event: "AI Search of Law Cases" },
          dispatch
        );
        if (!success) {
          console.error("Failed to deduct credits after successful API call.");
        }
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: "GET_SEARCH_RESULTS_FAIL",
        payload: "Failed to fetch chat titles.",
      });
    }
  };

export const findCaseFromAzureAiSearch =
  (citation, court) => async (dispatch) => {
    try {
      dispatch({
        type: "CASE_DETAIL_REQUEST",
      });
      const endpoint = `${config.azureCasesIndexUrl}`;
      const apiKey = config.apiKeyV4;
      const requestBody = {
        filter: `Case_Id eq '${citation} ${court}'`,
      };
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "api-key": apiKey,
        },
        body: JSON.stringify(requestBody),
      });
      if (response.ok) {
        const responseData = await response.json();
        dispatch({
          type: "CASE_DETAIL_SUCCESS",
          payload: responseData,
        });
      }
    } catch (error) {
      dispatch({
        type: "CASE_DETAIL_RESET",
        payload: "Failed to fetch case details.",
      });
    }
  };

// export const findCaseSummary = (citation) => async (dispatch) => {
//   try {
//     dispatch({
//       type: 'CASE_DETAIL_REQUEST',
//     });
//     const endpoint = `${config.backendUrl}/getCaseDetails`;
//     const requestBody = {
//       citation: citation,
//     };
//     const response = await fetch(endpoint, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//         'api-key': apiKey,
//       },
//       body: JSON.stringify(requestBody),
//     });
//     if (response.ok) {
//       const responseData = await response.json();
//       dispatch({
//         type: 'CASE_DETAIL_SUCCESS',
//         payload: responseData,
//       });
//     }
//   } catch (error) {
//     dispatch({
//       type: 'CASE_DETAIL_RESET',
//       payload: 'Failed to fetch case details.',
//     });
//   }
// };

export const findCaseSummary = (citation) => async (dispatch) => {
  try {
    dispatch({
      type: "CASE_DETAILS_FROM_COSMOS_REQUEST",
    });
    const endpoint = `${baseUrl}/getCaseDetails`;
    const requestBody = {
      citation: citation,
    };
    const response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });
    if (response.ok) {
      const responseData = await response.json();
      dispatch({
        type: "CASE_DETAILS_FROM_COSMOS_SUCCESS",
        payload: responseData,
      });
    }
  } catch (error) {
    dispatch({
      type: "CASE_DETAILS_FROM_COSMOS_FAIL",
      payload: "Failed to fetch case details.",
    });
  }
};

export const applyFilters =
  (searchQuery, filters, skip, top, mode, sortBy) =>
  async (dispatch, getState) => {
    try {
      const state = getState();
      const { credits, userId } = state.credit; // Replace `state.user` with the correct slice for user details
      let creditsToDeduct = 1;
      if (credits <= 0 || credits <= creditsToDeduct) {
        dispatch(showInsufficientCreditsModal()); // Trigger modal if credits are insufficient
        return;
      }
      dispatch({
        type: "GET_SEARCH_RESULTS_REQUEST",
      });

      const formatFilterString = (filterObject) => {
        const mapping = {
          Journal: "Section",
          Court: "Court",
          Year: "Year",
          Judge: "Judge_Name",
          Lawyer: "Lawyer_Name",
          Citation_Name: "Citation_Name",
        };

        const formattedFilters = Object.keys(filters).reduce((acc, key) => {
          const newKey = mapping[key];
          const value = filters[key];
          if (key === "Year" && value.startYear && value.endYear) {
            // Handle Year range
            if (value.startYear === value.endYear) {
              acc.push(`(Year eq '${value.startYear}')`);
            } else {
              acc.push(
                `(Year ge '${value.startYear}' and Year le '${value.endYear}')`
              );
            }
          } else if (Array.isArray(value) && value.length > 0) {
            const conditions = value
              .map((v) => `${newKey} eq '${v}'`)
              .join(" or ");
            acc.push(`(${conditions})`);
          } else if (newKey === "Lawyer_Name" || newKey === "Judge_Name") {
            if (value) {
              acc.push(`(${newKey}/any(t: t eq '${value}'))`);
            }
          } else if (!Array.isArray(value) && value) {
            acc.push(`(${newKey} eq '${value}')`);
          }

          return acc;
        }, []);
        return formattedFilters.join(" and ");
      };

      const endpoint = `${config.caseSearchActiveUrl}/lawcases`;
      const requestBody = {
        count: true,
        skip: skip,
        top: top,
        facets: [],
        mode: mode,
        sort_by: sortBy,
        filter: formatFilterString(filters),
        query: searchQuery,
      };
      //check here credits else return
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });
      if (response.ok) {
        const responseData = await response.json();
        dispatch({
          type: "GET_SEARCH_RESULTS_SUCCESS",
          payload: responseData,
        });
        // Deduct credits after successful API call
        const success = deductCredits(
          { userId, creditsToDeduct, event: "Filters of case search" },
          dispatch
        );
        if (!success) {
          console.error("Failed to deduct credits after successful API call.");
        }
      }
    } catch (error) {
      dispatch({
        type: "GET_SEARCH_RESULTS_FAIL",
        payload: "Failed to fetch chat titles.",
      });
    }
  };
