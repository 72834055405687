export const getHighlightedSentences = (description) => {
  if (!description || description.trim() === "") return "";

  let sentences = [];

  // Split the string into sentences using delimiters like ., ?, !, etc.
  const splitSentences = description?.split(/(?<=[.!?,])\s+/); // Split on punctuation followed by space
  sentences = sentences.concat(splitSentences); // Merge into one array of sentences

  // Prioritize sentences that contain <span> tags (highlighted parts)
  const highlightedSentences = sentences?.filter((sentence) =>
    /<span/.test(sentence)
  );
  const unhighlightedSentences = sentences?.filter(
    (sentence) => !/<span/.test(sentence)
  );

  // Combine the highlighted sentences first, followed by the unhighlighted ones
  const resultSentences = [...highlightedSentences, ...unhighlightedSentences];

  // Join the resulting sentences back together into a single string
  return resultSentences.join(" ").trim();
};
