import config from "../config/api";

export const getPetitionHistory = (userId, chatId) => async (dispatch) => {
  try {
    dispatch({
      type: "PETITION_HISTORY_REQUEST",
    });
    const endpoint = `${config.petitionUrl}/get_petition`;
    const data = { user_id: userId, chat_id: chatId };
    const response = await fetch(endpoint, {
      method: "POST",
      body: JSON.stringify(data),
    });

    if (response.ok) {
      const responseData = await response.json();
      dispatch({
        type: "PETITION_HISTORY_SUCCESS",
        payload: { ...responseData, chatId },
      });
    } else {
      console.log("Chat history not found");
    }
  } catch (error) {
    dispatch({
      type: "PETITION_HISTORY_FAIL",
      payload: error.response?.data?.message ?? error.message,
    });
  }
};

export const editPetitionTitle =
  (userId, chatId, newTitle) => async (dispatch) => {
    try {
      const endpoint = `${config.petitionUrl}/UpdatePetitionTitle`;
      const response = await fetch(endpoint, {
        method: "POST",
        body: JSON.stringify({
          user_id: userId,
          chat_id: chatId,
          Title: newTitle,
        }),
      });
      if (response.ok) {
        const responseData = await response.text();
        console.log(responseData);
        dispatch({
          type: "EDIT_PETITION_TITLE",
          payload: { chatId, newTitle },
        });
        console.log("Title updated");
      } else {
        console.log("Title not found");
      }
    } catch (error) {
      console.log(error.response?.data?.message ?? error.message);
    }
  };

export const deletePetition = (userId, chatId) => async (dispatch) => {
  try {
    const endpoint = `${config.petitionUrl}/DeletePetition`;
    const response = await fetch(endpoint, {
      method: "POST",
      body: JSON.stringify({ user_id: userId, chat_id: chatId }),
    });
    if (response.ok) {
      const responseData = await response.text();
      dispatch({
        type: "DELETE_PETITION_TITLE",
        payload: { chatId },
      });
      console.log("Chat deleted");
    } else {
      console.log("Chat not found");
    }
  } catch (error) {
    dispatch({
      type: "DELETE_PETITION_FAIL",
      payload: error.response?.data?.message ?? error.message,
    });
  }
};

export const togglePinPetition =
  (userId, chatId, isPinned) => async (dispatch) => {
    try {
      const endpoint = `${config.petitionUrl}/${
        isPinned ? "UnpinPetition" : "PinPetition"
      }`;

      const response = await fetch(endpoint, {
        method: "POST",
        body: JSON.stringify({ user_id: userId, chat_id: chatId }),
      });
      if (response.ok) {
        dispatch({
          type: "TOGGLE_PIN_PETITION",
          payload: { chatId, isPinned: !isPinned },
        });
      }
    } catch (error) {
      dispatch({
        type: "GET_CHAT_TITLES_FAIL",
        payload: "Failed to pin/unpin chat",
      });
    }
  };

export const getPetitionTitles = (userId) => async (dispatch, getState) => {
  const state = getState();
  console.log(state);
  
  try {
    if (state.petitionTitles.titles?.length === 0) {
      dispatch({
        type: "GET_PETITION_TITLES_REQUEST",
      });
    }
    const endpoint = `${config.petitionUrl}/HistoryPetition`;

    const response = await fetch(endpoint, {
      method: "POST",
      body: JSON.stringify({ user_id: userId }),
    });
    if (response.ok) {
      const responseData = await response.json();
      dispatch({
        type: "GET_PETITION_TITLES_SUCCESS",
        payload: responseData,
      });
    }
  } catch (error) {
    dispatch({
      type: "GET_PETITION_TITLES_FAIL",
      payload:
        "Petition history could not be fetched at this moment. Please try again later.",
    });
  }
};

export const savePetition = (userId, chatId) => async (dispatch) => {
  try {
    const endpoint = `${config.petitionUrl}/SavePetition`;

    const response = await fetch(endpoint, {
      method: "POST",
      body: JSON.stringify({ user_id: userId, chat_id: chatId }),
    });
    if (response.ok) {
      const responseData = await response.json();
      dispatch({
        type: "UPDATE_IS_SAVED_PETITION",
        payload: { chatId },
      });
      return responseData;
    }
  } catch (error) {
    console.log("Error saving petition", error);
    throw error;
  }
};
