const applicationTitlesInitialState = {
  titles: [],
  loading: false,
  error: null,
};

export const applicationHistoryReducer = (state = {}, action) => {
  switch (action.type) {
    case "APPLICATION_HISTORY_REQUEST":
      return { loading: true };
    case "APPLICATION_HISTORY_SUCCESS":
      return {
        loading: false,
        history: action.payload,
        historyReset: false,
        chatId: action.payload.chatId,
      };
    case "APPLICATION_HISTORY_FAIL":
      return { loading: false, error: action.payload };
    case "APPLICATION_HISTORY_RESET":
      return {};
    default:
      return state;
  }
};

export const editApplicationTitleReducer = (state = {}, action) => {
  switch (action.type) {
    case "EDIT_APPLICATION_TITLE_REQUEST":
      return { loading: true };
    case "EDIT_APPLICATION_TITLE_SUCCESS":
      return { loading: false, message: action.payload, success: true };
    case "EDIT_APPLICATION_TITLE_FAIL":
      return { loading: false, error: action.payload };
    case "EDIT_APPLICATION_TITLE_RESET":
      return {};
    default:
      return state;
  }
};

export const deleteApplicationReducer = (state = {}, action) => {
  switch (action.type) {
    case "DELETE_APPLICATION_REQUEST":
      return { loading: true };
    case "DELETE_APPLICATION_SUCCESS":
      return { loading: false, message: action.payload, success: true };
    case "DELETE_APPLICATION_FAIL":
      return { loading: false, error: action.payload };
    case "DELETE_APPLICATION_RESET":
      return {};
    default:
      return state;
  }
};

export const newapplicationReducer = (state = {}, action) => {
  switch (action.type) {
    case "NEW_APPLICATION_REQUEST":
      return { isClicked: true };
    case "NEW_APPLICATION_RESET":
      return {};
    default:
      return state;
  }
};

export const selectedApplicationReducer = (state = {}, action) => {
  switch (action.type) {
    case "SET_SELECTED_APPLICATION":
      return { selectedApp: action.payload };
    case "SELECTED_APPLICATION_RESET":
      return {};
    default:
      return state;
  }
};

export const applicationTitlesReducer = (
  state = applicationTitlesInitialState,
  action
) => {
  switch (action.type) {
    case "GET_APPLICATION_TITLES_REQUEST":
      return {
        ...state,
        loading: true,
        error: "",
      };
    case "GET_APPLICATION_TITLES_SUCCESS":
      return {
        ...state,
        titles: action.payload,
        loading: false,
        error: "",
      };
    case "EDIT_APPLICATION_TITLE":
      return {
        ...state,
        titles: state.titles?.map((title) =>
          title.chat_id === action.payload.chatId
            ? { ...title, Title: action.payload.newTitle }
            : title
        ),
        error: "",
      };
    case "DELETE_APPLICATION_TITLE":
      return {
        ...state,
        titles: state.titles?.filter(
          (chat) => chat.chat_id !== action.payload.chatId
        ),
        error: "",
      };
    case "TOGGLE_PIN_APPLICATION":
      return {
        ...state,
        titles: state.titles?.map((chat) =>
          chat.chat_id === action.payload.chatId
            ? { ...chat, isPinned: action.payload.isPinned }
            : chat
        ),
        error: "",
      };
    case "UPDATE_IS_SAVED_APPLICATION":
      return {
        ...state,
        titles: state.titles?.map((chat) =>
          chat.chat_id === action.payload.chatId
            ? { ...chat, isSaved: true }
            : chat
        ),
      };
    case "GET_APPLICATION_TITLES_FAIL":
      return { loading: false, error: action.payload };
    case "GET_APPLICATION_TITLES_RESET":
      return applicationTitlesInitialState;
    default:
      return state;
  }
};
