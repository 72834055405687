import React, { useState, useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Typography,
  TextField,
  CircularProgress,
  IconButton,
  InputAdornment,
} from "@mui/material";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw"; 
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
const CaseSummary = ({ selectedSection }) => {
  const caseDetailsFromCosmos = useSelector(
    (state) => state.caseDetailsFromCosmos
  );
  const { loading: loadingAdditionalDetails, caseItem: additionalCaseDetails } =
    caseDetailsFromCosmos;

  const [searchTerm, setSearchTerm] = useState("");
  const [currentMatchIndex, setCurrentMatchIndex] = useState(0);
  const [highlightedElements, setHighlightedElements] = useState([]);
  const [totalMatches, setTotalMatches] = React.useState(0);
  const [currentMatch, setCurrentMatch] = React.useState(0);
   
  const markRefs = useRef([]);

  const generateId = (text) => {
    return text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^a-z0-9-]/g, "");
  };

  const handlePreviousMatch = () => {
    if (highlightedElements.length === 0 || currentMatch === 1) return;

    const prevIndex =
      (currentMatchIndex - 1 + highlightedElements.length) %
      highlightedElements.length;
    const prevElement = document.getElementById(`match-${prevIndex}`);

    if (prevElement) {
      prevElement.scrollIntoView({ behavior: "smooth", block: "center" });
      prevElement.classList.add("active-match");

      // Remove "active" class from the previous element
      const currentElement = document.getElementById(
        `match-${currentMatchIndex}`
      );
      if (currentElement) {
        currentElement.classList.remove("active-match");
      }

      setCurrentMatchIndex(prevIndex);
      setCurrentMatch(prevIndex + 1); // Update current match (1-based index)
    }
  };

  const handleNextMatch = () => {
    if (highlightedElements.length === 0 || currentMatch === totalMatches)
      return;

    const nextIndex = (currentMatchIndex + 1) % highlightedElements.length;
    const nextElement = document.getElementById(`match-${nextIndex}`);

    if (nextElement) {
      nextElement.scrollIntoView({ behavior: "smooth", block: "center" });
      nextElement.classList.add("active-match");

      // Remove "active" class from the previous element
      const currentElement = document.getElementById(
        `match-${currentMatchIndex}`
      );
      if (currentElement) {
        currentElement.classList.remove("active-match");
      }

      setCurrentMatchIndex(nextIndex);
      setCurrentMatch(nextIndex + 1); // Update current match (1-based index)
    }
  };

  const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  };

  const highlightedContent = useMemo(() => {
    if (!searchTerm) {
      setHighlightedElements([]);
      setTotalMatches(0);
      return additionalCaseDetails?.generatedHeadNotes;
    }

    const keywordPattern = new RegExp(`(${escapeRegExp(searchTerm)})`, "gi");
    let matchIndex = 0;

    const contentWithHighlights =
      additionalCaseDetails?.generatedHeadNotes.split(keywordPattern);

    const highlighted = contentWithHighlights
      .map((part, index) => {
        if (keywordPattern.test(part)) {
          return `<mark id="match-${matchIndex++}">${part}</mark>`;
        }
        return part;
      })
      .join("");

    setHighlightedElements([...Array(matchIndex).keys()]);
    setTotalMatches(matchIndex); // Update total matches

    return highlighted;
  }, [additionalCaseDetails?.generatedHeadNotes, searchTerm]);

  useEffect(() => {
    let previousSection = null;
    if (
      selectedSection === "case-information" ||
      selectedSection === "legal-provisions" ||
      selectedSection === "case-summary" ||
      selectedSection === "courts-findings" ||
      selectedSection === "case-law-references"
    ) {
      const section = document.getElementById(selectedSection);

      if (previousSection) {
        previousSection.style.backgroundColor = "";
      }
      if (section) {
        section.scrollIntoView({ behavior: "smooth", block: "start" });
        section.style.borderLeft = "3px solid orange";
        previousSection = section;
      }
    }

    return () => {
      if (previousSection) {
        previousSection.style.backgroundColor = "";
      }
    };
  }, [selectedSection]);

  useEffect(() => {
    if (currentMatchIndex !== -1 && markRefs.current[currentMatchIndex]) {
      markRefs.current[currentMatchIndex].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [currentMatchIndex]);

  useEffect(() => {
    setCurrentMatchIndex(0);
  }, [searchTerm]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        flex: 5,
        flexGrow: 1,
        boxSizing: "border-box",
        bgcolor: "#f5f5f5",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          bgcolor: "#f5f5f5",
          mt: "8px",
        }}
      >
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{
            backgroundColor: "white",
            margin: "8px 8px 4px 8px",
            borderRadius: "24px",
            "& .MuiInputBase-root": {
              height: "40px",
              fontSize: "14px",
            },
            "& .MuiOutlinedInput-root": {
              borderRadius: "24px",
            },
            "& input": {
              padding: "8px",
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ color: "#303f9f" }} />
              </InputAdornment>
            ),
            endAdornment: searchTerm && (
              <InputAdornment position="end">
                <Typography variant="body2" sx={{ mr: 1 }}>
                  {currentMatch}/{totalMatches}
                </Typography>
                <IconButton
                  size="small"
                  onClick={handlePreviousMatch}
                  disabled={totalMatches === 0}
                >
                  <KeyboardArrowUpIcon
                    fontSize="small"
                    sx={{ color: "#303f9f" }}
                  />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={handleNextMatch}
                  disabled={totalMatches === 0}
                >
                  <KeyboardArrowDownIcon
                    fontSize="small"
                    sx={{ color: "#303f9f" }}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
      {(selectedSection === "Case Summary" ||
        selectedSection === "case-information" ||
        selectedSection === "legal-provisions" ||
        selectedSection === "case-summary" ||
        selectedSection === `courts-findings` ||
        selectedSection === "case-law-references") && (
        <React.Fragment>
          {loadingAdditionalDetails ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "24px",
                width: "100%",
              }}
            >
              <CircularProgress
                size={20}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              />
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                boxSizing: "border-box",
                flexDirection: "column",
                height: "100%",
                overflowY: "auto",
                bgcolor: "#ffffff",
                p: "8px 12px",
                m: 1,
                borderRadius: "8px",
                "::-webkit-scrollbar": {
                  width: "5px",
                },
                "::-webkit-scrollbar-track": {
                  background: "#fff",
                },
                "::-webkit-scrollbar-thumb": {
                  background: "#bbb",
                  borderRadius: "6px",
                },
              }}
            >
              <Typography sx={{ textAlign: "justify" }}>
                <Markdown
                  rehypePlugins={[rehypeRaw]}
                  components={{
                    h1: ({ node, ...props }) => {
                      const headingText = props.children;
                      const headingId = generateId(headingText);
                      return (
                        <Typography
                          id={headingId}
                          sx={{
                            fontSize: "18px",
                            fontWeight: "bold",
                            color: "#303f9f",
                            borderBottom: "1px solid #e6e6e6",
                            padding: "8px 4px",
                            marginBottom: "12px",
                            marginTop: "8px",
                            m: "8px 0px 12px 5px",
                          }}
                          {...props}
                        />
                      );
                    },
                    h3: ({ node, ...props }) => (
                      <Typography
                        variant="body1"
                        sx={{
                          fontWeight: "bold",
                          marginBottom: "4px",
                        }}
                        {...props}
                      />
                    ),
                    h4: ({ node, ...props }) => (
                      <Typography
                        variant="subtitle2"
                        sx={{
                          fontWeight: "bold",
                          marginBottom: "6px",
                          marginTop: "6px",
                        }}
                        {...props}
                      />
                    ),
                    p: ({ node, ...props }) => (
                      <Typography
                        sx={{
                          fontSize: "14px",
                          marginBottom: "6px",
                        }}
                        {...props}
                      />
                    ),
                    ol: ({ node, ...props }) => (
                      <Box
                        component="ol"
                        sx={{
                          fontSize: "14px",
                          marginBottom: "6px",
                          paddingLeft: "20px",
                          listStyleType: "decimal",
                        }}
                        {...props}
                      />
                    ),
                    ul: ({ node, ...props }) => (
                      <Box
                        component="ul"
                        sx={{
                          fontSize: "14px",
                          marginBottom: "6px",
                          paddingLeft: "20px",
                          listStyleType: "disc",
                        }}
                        {...props}
                      />
                    ),
                    li: ({ node, ...props }) => (
                      <Typography
                        component="li"
                        sx={{
                          fontSize: "14px",
                          marginBottom: "4px",
                        }}
                        {...props}
                      />
                    ),
                    mark: ({ node, ...props }) => (
                      <Typography
                        component="mark"
                        sx={{
                          backgroundColor:
                            props.id === `match-${currentMatchIndex}`
                              ? "#ff9800"
                              : "#ffeb3b",
                          padding: "0 2px",
                          fontSize: "inherit",
                          fontWeight: "inherit",
                        }}
                        {...props}
                      />
                    ),
                  }}
                >
                  {highlightedContent}
                </Markdown>
              </Typography>
            </Box>
          )}
        </React.Fragment>
      )}
    </Box>
  );
};

export default CaseSummary;
