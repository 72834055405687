import * as React from "react";
import { useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Stack } from "@mui/system";
import {
  CircularProgress,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import Snackbar from "@mui/material/Snackbar";
import { trackEvent } from "../analytics";
import config from "../config/api";
import { toast } from "react-toastify";
const baseUrl = process.env.REACT_APP_API_BASE_URL;
export default function Feedback({
  open,
  modelResponse,
  feedbackQuery,
  references,
  handleClose,
  messageIndex,
  feedbackGiven,
}) {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [selectedOption, setSelectedOption] = React.useState(null);
  const [userFeedback, setUserFeedback] = React.useState("");
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);

  const handleFeedbackChange = (e) => {
    const feedback = e.target.value;
    if (feedback.length > 2000) {
      setError(true);
    } else {
      setError(false);
    }
    setUserFeedback(feedback);
  };
  const handleClickChip = (label) => {
    setSelectedOption(label === selectedOption ? null : label);
  };
  const isChipSelected = (label) => selectedOption === label;

  const handleCloseDialog = () => {
    handleClose();
    setSelectedOption(null);
    setUserFeedback("");
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleSubmitFeedback = async () => {
    try {
      setLoading(true);
      const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const response = await fetch(
        `${baseUrl}/legalChat/v1/feedback`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: userInfo._id,
            username: userInfo.name,
            response: modelResponse,
            query: feedbackQuery,
            references: references,
            option: selectedOption,
            feedback: userFeedback,
            timezone: userTimezone,
          }),
        }
      );
      if (response.ok) {
        feedbackGiven(messageIndex);
        setOpenSnackbar(true);
        setLoading(false);
        handleClose();
        setSelectedOption(null);
        setUserFeedback("");
        trackEvent("Feedback", "Feedback Submission", `User: ${userInfo.name}`);
      } else {
        toast.error("An error occurred while submitting your feedback.");
        throw new Error("Something went wrong.");
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Dialog open={open} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
        <Box
          sx={{
            display: "flex",
            marginTop: "5px",
          }}
        >
          <DialogTitle sx={{ color: "#003D78", fontWeight: "bold" }}>
            Provide Feedback
          </DialogTitle>
          <IconButton
            sx={{ position: "absolute", right: 8, top: 14 }}
            onClick={handleCloseDialog}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <Divider />
        <DialogContent>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 2,
              flexWrap: "wrap",
            }}
          >
            {[
              "Not factually correct",
              "Outdated info",
              "References are irrelevant",
              "Unclear response",
              "Other",
            ].map((label) => (
              <Chip
                sx={{
                  cursor: "pointer",
                  bgcolor: isChipSelected(label) ? "#003D78" : "default",
                  color: isChipSelected(label) ? "white" : "default",
                  "&:hover": {
                    bgcolor: isChipSelected(label) ? "#003D78" : "action.hover",
                  },
                }}
                key={label}
                label={label}
                variant={isChipSelected(label) ? "filled" : "outlined"}
                onClick={() => handleClickChip(label)}
              />
            ))}
          </Stack>
          <TextField
            sx={{
              marginTop: "25px",
              "& .MuiOutlinedInput-root": {
                borderRadius: "10px",
                fontSize: "14px",
              },
            }}
            fullWidth
            placeholder="(Optional) Feel free to add specific details up to 2000 characters"
            id="outlined-size-small"
            size="small"
            value={userFeedback}
            onChange={handleFeedbackChange}
            error={error}
            helperText={error && "Maximum character limit of 2000 exceeded."}
          />
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "right",
            marginBottom: "5px",
            padding: "0 24px 15px 24px ",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            startIcon={
              loading ? (
                <CircularProgress size={16} sx={{ color: "#FFFFFF" }} />
              ) : (
                ""
              )
            }
            sx={{
              borderRadius: 30,
              backgroundColor: "#003D78",
              textTransform: "none",
              fontSize: "14px",
              "&:hover": {
                backgroundColor: "#003D78",
              },
              "&.Mui-disabled": {
                backgroundColor: "#cccccc",
                color: "#666666",
                borderColor: "#cccccc",
                cursor: "not-allowed",
                pointerEvents: "auto",
              },
              "&.Mui-disabled:hover": {
                backgroundColor: "#cccccc",
              },
            }}
            disabled={!selectedOption || error}
            onClick={handleSubmitFeedback}
          >
            {loading ? "Submitting..." : "Submit"}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        message="Thank you for the feedback!"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
    </>
  );
}
