import { Box } from "@mui/system";
import React from "react";

const NotFound = () => {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100vw",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <p>You caught us, we are still working on it.</p>
    </Box>
  );
};

export default NotFound;
