import React, { useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Modal,
  Box,
  Typography,
  Button,
  IconButton,
  Divider,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {
  hasSeenCreditsModal,
  hideInsufficientCreditsModal,
  requestCredits,
  setCredits,
  updateCredits,
} from "../../actions/creditActions";
import CongratulationsModal from "./CongratulationModal";
import axios from "axios";
const baseUrl = process.env.REACT_APP_API_BASE_URL;
const InsufficientCreditsModal = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [congratsModalOpen, setCongratsModalOpen] = useState(false);
  const maxRefillCount = 3;
  const modalOpen = useSelector(
    (state) => state.credit.insufficientCreditsModalOpen
  );
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const { credits, creditRequestCount } = useSelector((state) => state.credit);
  const handleClose = () => {
    dispatch(hideInsufficientCreditsModal());
  };
  const handleRequestCredit = useCallback(async () => {
    if (creditRequestCount < maxRefillCount) {
      setLoading(true);
      try {
        const response = await dispatch(requestCredits(userInfo._id));
        if (response?.success) {
          setCongratsModalOpen(true);
          handleClose();
          dispatch(
            updateCredits({
              credits: response.credits,
              expirationDate: response.expirationDate,
              creditRequestCount: creditRequestCount + 1,
            })
          );
          fetchUserProfile();
        }
      } catch (error) {
        console.error("Failed to request credits:", error);
      } finally {
        setLoading(false);
      }
    }
  }, [dispatch, userInfo._id, creditRequestCount]);

  const handleCongratsModalClose = () => {
    setCongratsModalOpen(false);
  };
  const getTooltipTitle = () => {
    if (credits > 20) {
      return "You have enough credits. Please consume before requesting a refill.";
    } else if (creditRequestCount >= maxRefillCount) {
      return "You've reached the maximum number of refill requests. Please contact support for assistance.";
    } else {
      return `Refill Requests: ${creditRequestCount}/${maxRefillCount}`;
    }
  };

  const fetchUserProfile = async () => {
    try {
      if (!userInfo?._id) {
        console.warn("User ID not available.");
        return;
      }

      const response = await axios.get(`${baseUrl}/getUserProfile`, {
        params: { userId: userInfo._id }, // Pass user ID as a query parameter
      });

      if (response.status === 200) {
        const data = response.data;
        // Dispatch actions to update the Redux store
        dispatch({
          type: "USER_LOGIN_SUCCESS",
          payload: data,
        });
        dispatch(
          setCredits(
            data.credits,
            data._id,
            data.creditExpiration,
            data.creditRequestCount
          )
        );

        dispatch(hasSeenCreditsModal(data.hasSeenModal));
      }
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };

  return (
    <>
      <Modal
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={modalOpen}
        onClose={handleClose}
      >
        <Box
          sx={{
            position: "relative",
            width: 400,
            bgcolor: "background.paper",
            borderRadius: 4,
            boxShadow: 24,
            p: 4,
            "@media (max-width:600px)": {
              width: "90%",
            },
          }}
        >
          <IconButton
            onClick={handleClose}
            aria-label="close"
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "grey.500",
            }}
          >
            <CloseIcon />
          </IconButton>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            textAlign="center"
          >
            <ErrorOutlineIcon
              sx={{
                fontSize: 48,
                color: "error.main",
                mb: 2,
              }}
            />
            <Typography variant="h5" component="h2" gutterBottom>
              Credit Refill Needed
            </Typography>
            <Divider sx={{ width: "100%", my: 2 }} />
            <Typography variant="body1" sx={{ mb: 3 }}>
              Your credit balance has reached 0. To continue using LawGPT,
              please refill your credits. You can request 100 additional credits
              up to 3 times during the beta phase.
            </Typography>
            <Tooltip
              title={getTooltipTitle()}
              arrow
              placement="left"
              sx={{
                maxWidth: 220,
                "& .MuiTooltip-tooltip": {
                  fontSize: "12px",
                  lineHeight: 1.4,
                  padding: "8px 12px",
                  textAlign: "center",
                },
              }}
            >
              <Button
                onClick={handleRequestCredit}
                variant="contained"
                color="primary"
                size="large"
                disabled={
                  credits > 20 ||
                  creditRequestCount >= maxRefillCount ||
                  loading
                }
                sx={{
                  borderRadius: 30,
                  backgroundColor: "#003D78",
                  px: 4,
                  py: 1,
                  textTransform: "none",
                  fontSize: "1rem",
                  "&.Mui-disabled": {
                    backgroundColor: "#cccccc",
                    color: "#666666",
                    borderColor: "#cccccc",
                    cursor: "not-allowed",
                    pointerEvents: "auto",
                  },
                  "&.Mui-disabled:hover": {
                    backgroundColor: "#cccccc",
                  },
                }}
              >
                {loading ? "Requesting..." : "Request Credits"}
              </Button>
            </Tooltip>
          </Box>
        </Box>
      </Modal>
      <CongratulationsModal
        open={congratsModalOpen}
        onClose={handleCongratsModalClose}
        credits={500}
      />
    </>
  );
};

export default InsufficientCreditsModal;
