import React, { useState, useRef, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  TextField,
  FormControl,
  IconButton,
  Switch,
  FormControlLabel,
  Typography,
  ListItem,
  ListItemText,
  List,
  Paper,
  ListItemButton,
  CircularProgress,
} from "@mui/material";
import { debounce } from "lodash";
import SearchIcon from "@mui/icons-material/Search";
import InfoIcon from "@mui/icons-material/Info";
import { searchResults } from "../../actions/searchActions";
import { KeyboardVoice } from "@mui/icons-material";
import InfoDialog from "./InfoDialog";
import { showInsufficientCreditsModal } from "../../actions/creditActions";
import { deductCredits } from "../../utils/HelperFunction/deductCredits";
import SuggestionsDropdown from "./SuggestionsDropdown";

const CaseLawSearchBar = ({
  resultsCount,
  updateResultsCount,
  sortBy,
  updateSortBy,
  searchMode,
  updateSearchMode,
  searchKeyword,
  setSearchKeyword,
  updateCurrentPage,
  setIsNewCaseLawSearch,
}) => {
  const backendApiUrl = process.env.REACT_APP_API_BASE_URL;

  const dispatch = useDispatch();
  const paperRef = useRef(null);
  const silenceTimeoutRef = useRef(null);
  const finalTranscriptRef = useRef("");
  const itemRefs = useRef([]);
  const latestQueryRef = useRef("");

  const credits = useSelector((state) => state.credit.credits);
  const userId = useSelector((state) => state.credit.userId);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [loadingSuggestions, setLoadingSuggestions] = useState(false);
  const [isListening, setIsListening] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isSpeechSupported, setIsSpeechSupported] = useState(true);
  const [placeholder, setPlaceholder] = useState("Search for case law...");
  const [isSearchClicked, setIsSearchClicked] = useState(false);
  const [isAdvancedSearch, setIsAdvancedSearch] = useState(false);
  const [openInfoDialog, setOpenInfoDialog] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [focusedIndex, setFocusedIndex] = useState(-1);

  const handleClickInfoDialog = () => {
    setOpenInfoDialog(true);
  };

  const fetchSuggestions = useCallback(
    debounce(async (query) => {
      if (!query.trim()) {
        setSuggestions([]);
        setIsDropdownOpen(false);
        return;
      }
      latestQueryRef.current = query;
      setLoadingSuggestions(true);
      try {
        const response = await fetch(
          `${backendApiUrl}/fetchSuggestions/v1?q=${query}&type=case`
        );
        const responseData = await response?.json();
        if (query === latestQueryRef.current) {
          setSuggestions(responseData?.data || []);
          setIsDropdownOpen(true);
        }
      } catch (error) {
        console.error("Error fetching suggestions:", error);
      } finally {
        setLoadingSuggestions(false);
      }
    }, 500),
    []
  );

  const handleCloseInfoDialog = () => {
    setOpenInfoDialog(false);
  };

  const handleSearch = async (keyword = searchKeyword) => {
    if (!keyword) return;

    let creditsToDeduct = 1;
    if (credits <= 0 || credits <= creditsToDeduct) {
      dispatch(showInsufficientCreditsModal());
      return;
    }
    dispatch(searchResults(keyword, 0, resultsCount, searchMode, sortBy));
    // Deduct credits using the helper
    const deductionSuccess = deductCredits(
      { userId, creditsToDeduct: 1, event: "Search bar on Case Search" },
      dispatch
    );

    if (!deductionSuccess) {
      console.warn(
        "Failed to deduct credits. Ensure retry or user notification."
      );
    }
    setIsNewCaseLawSearch(true);
    updateCurrentPage(1);
    setIsSearchClicked(true);
    setSuggestions([]);
  };

  const handleSwitchChange = (event) => {
    setIsAdvancedSearch(event.target.checked);
    if (event.target.checked) {
      updateSearchMode("vector");
      //setIsSearchClicked(false);
    } else {
      updateSearchMode("keyword");
    }
  };

  const getSpeechRecognition = () => {
    return window.SpeechRecognition || window.webkitSpeechRecognition || null;
  };

  const SpeechRecognitionConstructor = getSpeechRecognition();

  let recognition;
  if (SpeechRecognitionConstructor) {
    recognition = new SpeechRecognitionConstructor();
    recognition.continuous = true;
    recognition.interimResults = true;
    recognition.lang = "en-US";

    recognition.onresult = (event) => {
      const transcript = Array.from(event.results)
        .map((result) => result[0].transcript)
        .join("");
      setSearchKeyword(transcript);
      finalTranscriptRef.current = transcript;
      resetSilenceTimer();
    };

    recognition.onerror = (event) => {
      console.error(event.error);
      stopListening();
    };

    recognition.onend = () => {
      setIsListening(false);
      setIsProcessing(false);
      setPlaceholder("Search for case law...");
      clearSilenceTimer();
      if (finalTranscriptRef.current) {
        handleSearch(finalTranscriptRef.current);
      }
    };
  }

  const startListening = () => {
    if (recognition) {
      setIsListening(true);
      setPlaceholder("Speak...");
      recognition.start();
      startSilenceTimer();
    }
  };

  const stopListening = () => {
    if (recognition) {
      setIsProcessing(true);
      recognition.stop();
    }
  };

  const toggleListening = () => {
    if (!isSpeechSupported) {
      alert("Voice recognition is not supported on this browser.");
      return;
    }

    if (isListening) {
      stopListening();
    } else {
      startListening();
    }
  };

  const startSilenceTimer = () => {
    silenceTimeoutRef.current = setTimeout(() => {
      stopListening();
    }, 3000);
  };

  const resetSilenceTimer = () => {
    clearTimeout(silenceTimeoutRef.current);
    startSilenceTimer();
  };

  const clearSilenceTimer = () => {
    if (silenceTimeoutRef.current) {
      clearTimeout(silenceTimeoutRef.current);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setSearchKeyword(suggestion);
    setIsDropdownOpen(false);
    setSuggestions([]);
    handleSearch(suggestion);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchKeyword(value);
    setFocusedIndex(-1);

    if (value.trim() === "") {
      fetchSuggestions.cancel();
      setIsDropdownOpen(false);
      setSuggestions([]);
      return;
    }
    setIsDropdownOpen(true);
    fetchSuggestions(value);
  };

  const handleKeyDown = (e) => {
    //if (!isDropdownOpen || suggestions?.length === 0) return;

    if (e.key === "ArrowDown") {
      if (suggestions.length === 0) return;
      setFocusedIndex((prevIndex) => (prevIndex + 1) % suggestions.length);
    } else if (e.key === "ArrowUp") {
      if (suggestions.length === 0) return;
      setFocusedIndex((prevIndex) =>
        prevIndex === -1
          ? suggestions.length - 1
          : (prevIndex - 1 + suggestions.length) % suggestions.length
      );
    } else if (e.key === "Enter") {
      if (searchKeyword === "") return;
      handleSearch(suggestions[focusedIndex]?.text);
      setIsDropdownOpen(false);
      setSuggestions([]);
      setFocusedIndex(-1);
    } else if (e.key === "Escape") {
      setSuggestions([]);
      setIsDropdownOpen(false);
      setFocusedIndex(-1);
    }
  };

  // Update search bar value when focusedIndex changes
  useEffect(() => {
    if (focusedIndex >= 0) {
      setSearchKeyword(suggestions[focusedIndex]?.text);
    }
  }, [focusedIndex]);

  useEffect(() => {
    const isSupported =
      window.SpeechRecognition || window.webkitSpeechRecognition;
    setIsSpeechSupported(!!isSupported);
  }, []);

  useEffect(() => {
    if (searchKeyword === "" && isSearchClicked) {
      dispatch(
        searchResults(searchKeyword, 0, resultsCount, searchMode, sortBy)
      );
      setIsSearchClicked(false);
      updateCurrentPage(1);
    }
  }, [
    dispatch,
    resultsCount,
    sortBy,
    searchKeyword,
    searchMode,
    isSearchClicked,
    updateCurrentPage,
  ]);

  // Close dropdown on outside click
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (paperRef.current && !paperRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
        setSuggestions([]);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      fetchSuggestions.cancel();
    };
  }, [fetchSuggestions]);

  // Scroll to the focused item when focusedIndex changes
  useEffect(() => {
    if (focusedIndex >= 0 && itemRefs.current[focusedIndex]) {
      itemRefs.current[focusedIndex].scrollIntoView({
        behavior: "auto",
        block: "nearest",
      });
    }
  }, [focusedIndex]);

  return (
    <Box
      sx={{
        position: "relative",
        backgroundColor: "#fff",
        padding: "8px",
        borderRadius: "8px",
        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          m: "0 8px",
        }}
      >
        <TextField
          placeholder={placeholder}
          variant="outlined"
          value={searchKeyword}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          InputProps={{
            sx: {
              p: 0,
              backgroundColor: "#ffffff",
              borderRadius: "20px",
            },
            startAdornment: (
              <SearchIcon sx={{ color: "#303f9f", padding: "8px" }} />
            ),
            endAdornment: loadingSuggestions ? (
              <Box sx={{ display: "flex" }}>
                <CircularProgress size={18} sx={{ marginRight: "8px" }} />
              </Box>
            ) : (
              <IconButton
                sx={{
                  color: isListening ? "#FF4081" : "#303f9f",
                  animation: isListening ? "pulse 1.5s infinite" : "none",
                }}
                variant="contained"
                onClick={toggleListening}
                disabled={!isSpeechSupported || isProcessing}
              >
                <KeyboardVoice />
              </IconButton>
            ),
          }}
          sx={{
            width: "80%",
            //flexGrow: 1,
            marginRight: "16px",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#ccc",
              },
              "&:hover fieldset": {
                borderColor: "#00509e",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#00509e",
              },
            },
            "& input": {
              padding: "10px 10px",
              fontSize: "0.875rem",
            },
          }}
        />
        <FormControl sx={{ width: "9%" }} variant="outlined">
          <FormControlLabel
            control={
              <Switch
                size="small"
                checked={isAdvancedSearch}
                onChange={handleSwitchChange}
              />
            }
            label="AI Search"
            sx={{
              "& .MuiFormControlLabel-label": {
                fontSize: "13px",
              },
            }}
          />
        </FormControl>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "11%",
            //gap: "1px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <IconButton size="small" onClick={handleClickInfoDialog}>
            <InfoIcon fontSize="small" color="info" />
          </IconButton>
          <Typography sx={{ fontSize: "13px" }}>Search Modes</Typography>
        </Box>
      </Box>
      {/* Dropdown Suggestions */}
      {isDropdownOpen && suggestions?.length > 0 && (
        <SuggestionsDropdown
          suggestions={suggestions}
          focusedIndex={focusedIndex}
          onSuggestionClick={handleSuggestionClick}
          itemRefs={itemRefs}
          ref={paperRef}
        />
      )}
      <InfoDialog open={openInfoDialog} handleClose={handleCloseInfoDialog} />
    </Box>
  );
};

export default CaseLawSearchBar;
