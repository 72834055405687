import axios from "axios";
import { googleLogout } from "@react-oauth/google";
import config from "./../config/api";
import { hasSeenCreditsModal, setCredits } from "./creditActions";
import {
  FETCH_BOOKMARK_GROUPS_RESET,
  FETCH_CASES_RESET,
  FETCH_STATUTES_RESET,
} from "../store/Bookmarks/bookmarksTypes";
const baseUrl = process.env.REACT_APP_API_BASE_URL;
export const login = (username, password) => async (dispatch) => {
  try {
    dispatch({
      type: "USER_LOGIN_REQUEST",
    });

    const headerConfig = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const authFunctionUrl = `${baseUrl}/lawgptuserAuth`;
    const { data } = await axios.post(
      authFunctionUrl,
      { username: username.toLowerCase(), password: password },
      headerConfig
    );
    localStorage.setItem("userInfo", JSON.stringify(data));
    dispatch({
      type: "USER_LOGIN_SUCCESS",
      payload: data,
    });
    dispatch(
      setCredits(
        data.credits,
        data._id,
        data.creditExpiration,
        data.creditRequestCount
      )
    );

    dispatch(hasSeenCreditsModal(data.hasSeenModal));
  } catch (error) {
    dispatch({
      type: "USER_LOGIN_FAIL",
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const logout = () => async (dispatch) => {
  localStorage.removeItem("userInfo");
  googleLogout();
  dispatch({ type: "USER_LOGOUT" });
  dispatch({ type: "GET_CHAT_TITLES_RESET" });
  dispatch({ type: FETCH_BOOKMARK_GROUPS_RESET });
  dispatch({ type: FETCH_CASES_RESET });
  dispatch({ type: FETCH_STATUTES_RESET });
  dispatch({ type: "GET_PETITION_TITLES_RESET" });
  dispatch({ type: "GET_APPLICATION_TITLES_RESET" });
  // window.location.replace("/");
};

export const signUp =
  (
    firstName,
    lastName,
    email,
    phoneNumber,
    password,
    cnic,
    userType,
    organization,
    designation,
    officeAddress,
    barCouncil
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "USER_SIGNUP_REQUEST",
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
          "Frontend-Origin": window.location.origin, // Add your Frontend-Origin here
        },
      };
      const signUpUrl = `${baseUrl}/lawgptuserSignUp`;
      const { data } = await axios.post(
        signUpUrl,
        {
          firstName,
          lastName,
          email: email?.toLowerCase(),
          phoneNumber,
          password,
          cnic,
          userType,
          organization,
          designation,
          officeAddress,
          barCouncil,
        },
        config
      );
      localStorage.setItem("userInfo", JSON.stringify(data));
      dispatch({
        type: "USER_SIGNUP_SUCCESS",
        payload: data,
      });
      console.log(data);
    } catch (error) {
      dispatch({
        type: "USER_SIGNUP_FAIL",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
