import config from "../../config/api";
const baseUrl = process.env.REACT_APP_API_BASE_URL;
export const fetchBookmarkGroupsApi = async (userId) => {
  const response = await fetch(
    `${baseUrl}/getUserBookmarkGroups?userId=${userId}`
  );
  if (!response.ok) {
    throw new Error("Failed to fetch bookmark groups");
  }
  return await response.json();
};

export const renameGroupApi = async (userId, groupId, newGroupName) => {
  const response = await fetch(`${baseUrl}/renameBookmarkGroup`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      userId,
      groupId,
      newGroupName,
    }),
  });

  if (!response.ok) {
    throw new Error("Failed to rename the group");
  }

  return await response.json();
};

export const deleteGroupApi = async (userId, groupId) => {
  const response = await fetch(`${baseUrl}/deleteBookmarkGroup`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      userId,
      groupId,
    }),
  });

  if (!response.ok) {
    throw new Error("Failed to rename the group");
  }

  return await response.json();
};

export const createNewGroupApi = async (userId, groupName) => {
  const response = await fetch(`${baseUrl}/createBookmarkGroup`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      userId,
      groupName,
    }),
  });

  if (!response.ok) {
    throw new Error("Failed to rename the group");
  }

  return await response.json();
};

export const fetchCasesApi = async (filter) => {
  const response = await fetch(config.azureCasesIndexUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "api-key": config.apiKeyV4,
    },
    body: JSON.stringify({
      filter,
    }),
  });

  if (!response.ok) {
    throw new Error("Failed to fetch cases from Azure Search.");
  }

  return await response.json();
};

export const fetchStatutesApi = async (filter) => {
  const azureStatuteSearch = config.azureStatutesIndexUrl;
  const response = await fetch(azureStatuteSearch, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "api-key": config.apiKeyV3,
    },
    body: JSON.stringify({
      filter,
    }),
  });

  if (!response.ok) {
    throw new Error("Failed to fetch statutes from Azure Search.");
  }

  return await response.json();
};

export const updateNoteApi = async (
  userId,
  groupId,
  itemId,
  itemType,
  note
) => {
  const url = `${baseUrl}/updateNote`;
  try {
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId,
        groupId,
        itemId,
        itemType,
        note,
      }),
    });

    if (!response.ok) {
      throw new Error("Failed to update note");
    }

    return await response.json(); // Return the JSON response if needed
  } catch (error) {
    console.error("Error updating note:", error);
    throw error; // Re-throw the error to handle it in the component
  }
};

export const removeNoteApi = async (userId, groupId, itemId, itemType) => {
  const url = `${baseUrl}/removeNote`;
  try {
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId,
        groupId,
        itemId,
        itemType,
      }),
    });

    if (!response.ok) {
      throw new Error("Failed to remove note");
    }

    return await response.json(); // Return response data if needed
  } catch (error) {
    console.error("Error removing note:", error);
    throw error; // Re-throw the error for the calling function to handle
  }
};

export const copyBookmarkApi = async (
  userId,
  fromGroupId,
  toGroupId,
  caseId,
  statuteId
) => {
  try {
    const payload = {
      userId,
      fromGroupId,
      toGroupId,
      caseId,
      statuteId,
    };

    const response = await fetch(`${baseUrl}/moveCaseOrStatuteBetweenGroups`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      console.log("Response is not ok");
      throw new Error("Failed to copy bookmark");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(error.message || "Error copying bookmark");
  }
};
