import React from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  IconButton,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EmailIcon from "@mui/icons-material/Email";

const CreditsInfoModal = ({ open, onClose }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          position: "relative",
          width: 500,
          maxWidth: "95%",
          bgcolor: "background.paper",
          borderRadius: 4,
          boxShadow: 24,
          p: 4,
          maxHeight: "90vh",
          overflowY: "auto",
        }}
      >
        <IconButton
          onClick={onClose}
          aria-label="close"
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#003D78",
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography
            variant="h5"
            component="h2"
            gutterBottom
            sx={{
              fontWeight: "bold",
              textAlign: "center",
              color: "#003D78",
              mb: 2,
            }}
          >
            Credit Usage Policy
          </Typography>
          <Typography variant="body1" sx={{ mb: 2, textAlign: "center" }}>
            Welcome to LawGPT! Here's how your credits work:
          </Typography>
          <Divider sx={{ width: "100%" }} />
          <List sx={{ width: "100%" }}>
            <ListItem
              sx={{
                alignItems: "flex-start", // This applies to the ListItem directly
                "& .MuiListItemText-root": {
                  marginTop: "-2px", // Optional: Ensure alignment looks correct
                },
              }}
            >
              <ListItemIcon>
                <CreditScoreIcon sx={{ color: "#003D78" }} />
              </ListItemIcon>
              <ListItemText
                primary="Initial Credits"
                secondary="Get 1000 free credits upon registration"
              />
            </ListItem>
            <ListItem
              sx={{
                alignItems: "flex-start", // This applies to the ListItem directly
                "& .MuiListItemText-root": {
                  marginTop: "-2px", // Optional: Ensure alignment looks correct
                },
              }}
            >
              <ListItemIcon>
                <RemoveCircleIcon sx={{ color: "#003D78" }} />
              </ListItemIcon>
              <ListItemText
                primary="Where Credits Are Deducted"
                secondary={
                  <React.Fragment>
                    Credits are used for specific actions like:
                    <ul style={{ padding: 0, paddingLeft: "1rem" }}>
                      <li>
                        Legal Chat (queries, chat, case/statute-based
                        conversations)
                      </li>
                      <li>
                        Case & Statute Search (searches, filters, AI search
                        mode)
                      </li>
                      <li>
                        Bookmarks (creating, editing, managing groups and notes)
                      </li>
                      <li>
                        Application & Petition Drafting (generating drafts,
                        saving edits)
                      </li>
                      <li>
                        Document Analysis (uploading documents, starting chats)
                      </li>
                      <li>Legal Library (searching, marking as favorite)</li>
                    </ul>
                  </React.Fragment>
                }
              />
            </ListItem>
            <ListItem
              sx={{
                alignItems: "flex-start", // This applies to the ListItem directly
                "& .MuiListItemText-root": {
                  marginTop: "-2px", // Optional: Ensure alignment looks correct
                },
              }}
            >
              <ListItemIcon>
                <AddCircleIcon sx={{ color: "#003D78" }} />
              </ListItemIcon>
              <ListItemText
                primary="Additional Credits"
                secondary="Request 500 extra credits up to 3 times during the beta phase when credits drop below 20"
              />
            </ListItem>
            <ListItem
              sx={{
                alignItems: "flex-start", // This applies to the ListItem directly
                "& .MuiListItemText-root": {
                  marginTop: "-2px", // Optional: Ensure alignment looks correct
                },
              }}
            >
              <ListItemIcon>
                <EmailIcon sx={{ color: "#003D78" }} />
              </ListItemIcon>
              <ListItemText
                primary="Contact Us"
                secondary={
                  <Typography
                    sx={{ fontSize: "14px" }}
                    color="#00000099"
                    component="span"
                  >
                    For questions, contact{" "}
                    <Typography
                      component="span"
                      sx={{
                        fontWeight: "bold",
                        color: "#000000",
                        fontSize: "14px",
                      }}
                    >
                      <a
                        href="mailto:support@lawgpt.pk"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        support@lawgpt.pk
                      </a>
                    </Typography>
                  </Typography>
                }
              />
            </ListItem>
          </List>
          <Button
            onClick={onClose}
            variant="contained"
            size="large"
            sx={{
              mt: 3,
              borderRadius: 30,
              px: 4,
              py: 1.5,
              textTransform: "none",
              fontSize: "1rem",
              bgcolor: "#003D78",
              "&:hover": {
                bgcolor: "#002a54",
              },
              transition: "background-color 0.3s",
            }}
          >
            Got It
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default CreditsInfoModal;
