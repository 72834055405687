import React, { useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Container } from "@mui/system";
import SearchIcon from "@mui/icons-material/Search";
const theme = createTheme({
  palette: {
    primary: {
      main: "#003d78",
    },
  },
});

const videos = [
  {
    id: 1,
    title: "What is Legal Chat?",
    description:
      "Discover how Legal Chat in LawGPT simplifies legal research like never before!",
    videoId: "KYgkRfxhV-c", // Replace with actual YouTube video ID
    module: "Legal Chat",
    duration: "10:30",
  },
  {
    id: 2,
    title: "What is Case Search?",
    description:
      "Discover how Case Search in LawGPT revolutionizes legal research!",
    videoId: "-C76iexEPPo", // Replace with actual YouTube video ID
    module: "Case Search",
    duration: "8:45",
  },
  {
    id: 3,
    title: "Petition Management Overview",
    description:
      "Get an overview of the petition management features in LawGPT.",
    videoId: "n4z2EMuREH8", // Replace with actual YouTube video ID
    module: "Petition Management",
    duration: "12:15",
  },
  {
    id: 4,
    title: "Advanced Case Law Techniques",
    description:
      "Explore advanced techniques for case law research using LawGPT.",
    videoId: "U-qvcXEqnGI", // Replace with actual YouTube video ID
    module: "Case Law Search",
    duration: "15:00",
  },
  {
    id: 5,
    title: "Statute Interpretation Tools",
    description:
      "Learn about the statute interpretation tools available in LawGPT.",
    videoId: "P42FautgWNw", // Replace with actual YouTube video ID
    module: "Statute Search",
    duration: "11:20",
  },
  {
    id: 6,
    title: "Efficient Petition Filing",
    description: "Discover how to file petitions efficiently using LawGPT.",
    videoId: "RhtcETr6Sec", // Replace with actual YouTube video ID
    module: "Petition Management",
    duration: "9:55",
  },
];

const VideoCard = ({ video }) => (
  <Card
    sx={{
      height: "100%",
      borderRadius: "20px",
      display: "flex",
      flexDirection: "column",
      transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
      "&:hover": {
        transform: "translateY(-5px)",
        boxShadow: "0 4px 20px rgba(0,0,0,0.5)",
      },
    }}
  >
    <Box sx={{ position: "relative", paddingTop: "56.25%" }}>
      <iframe
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
        src={`https://www.youtube.com/embed/${video.videoId}?rel=0`}
        title={video.title}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </Box>
    <CardContent sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
      <Typography
        variant="h6"
        gutterBottom
        sx={{ fontSize: { xs: "1rem", sm: "1.25rem"} }}
      >
        {video.title}
      </Typography>
      <Typography
        variant="body2"
        color="text.secondary"
        paragraph
        sx={{ fontSize: { xs: "0.8rem", sm: "0.875rem"  } }}
      >
        {video.description}
      </Typography>
      <Box sx={{ mt: "auto" }}>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ fontSize: { xs: "0.75rem", sm: "0.8rem",  } }}
        >
          Module: {video.module}
        </Typography>
      </Box>
    </CardContent>
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        px: { xs: "8px", sm: "10px", md: "12px" },
        pb: { xs: "8px", sm: "10px", md: "12px" },
      }}
    >
      <Button
        variant="contained"
        color="primary"
        onClick={() =>
          window.open("https://www.youtube.com/@LawGPTapp", "_blank")
        }
        sx={{
          borderRadius: "24px",
          padding: { xs: "6px 18px", sm: "8px 24px", md: "10px 28px" },
          textTransform: "none",
          fontWeight: "bold",
          backgroundColor: "#003D78",
          fontSize: { xs: "0.75rem", sm: "0.875rem", md: "1rem" },
          "&:hover": { backgroundColor: "primary.main", color: "#fff" },
          transition: "background-color 0.3s ease-in-out",
        }}
      >
        Explore More
      </Button>
    </Box>
  </Card>
);

export const TutorialVideos = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const filteredVideos = videos.filter((video) =>
    video.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Container
      maxWidth={false}
      sx={{
        height: "calc(100vh - 50px)",
        width: "calc(100vw - 45px)",
        overflowY: "auto",
        "::-webkit-scrollbar": {
          width: "5px",
        },
        "::-webkit-scrollbar-track": {
          background: "#fff",
        },
        "::-webkit-scrollbar-thumb": {
          background: "#bbb",
          borderRadius: "6px",
        },
      }}
    >
      <ThemeProvider theme={theme}>
        <Box sx={{ display: "flex", minHeight: "100vh" }}>
          <Box
            sx={{
              padding: { xs: "2px", sm: "15px", md: "5px", lg: 8 },
              paddingTop: 0,
              width: "100%",
            }}
          >
            <Box
              textAlign="center"
              mb={2}
              sx={{
                width: "100%",
                padding: { xs: "2px", sm: "5px", md: "16px", lg: "24px" },
              }}
            >
              <Typography
                component="h2"
                color="text.primary"
                gutterBottom
                sx={{
                  fontSize: { xs: "24px", sm: "28px", md: "30px" },
                  fontWeight: 700,
                  color: "#303f9f",
                }}
              >
                Tutorial Videos
              </Typography>
              <Typography
                color="text.secondary"
                sx={{
                  maxWidth: "800px",
                  mx: "auto",
                  fontSize: { xs: "16px", sm: "18px", md: "20px" },
                }}
              >
                Mastering LawGPT – Your Guide to Smarter Legal Insights!
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                gap: 2,
                mb: 4,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  mb: 4,
                  pl: { md: "25px" },
                  mx: "auto",
                  width: "100%",
                  maxWidth: "650px",
                }}
              >
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Search tutorials..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  sx={{
                    backgroundColor: "#ffffff",
                    borderRadius: "20px",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#ccc",
                      },
                      "&:hover fieldset": {
                        borderColor: "#00509e",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#00509e",
                      },
                    },
                    "& input": {
                      padding: "10px 10px",
                      fontSize: "0.875rem",
                    },
                  }}
                  InputProps={{
                    sx: { borderRadius: 8, p: 0 },
                    startAdornment: (
                      <SearchIcon sx={{ color: "#303f9f", padding: "8px" }} />
                    ),
                  }}
                />
              </Box>
            </Box>

            <Grid container spacing={{ xs: 2, sm: 3, md: 4, lg: 5 }}>
              {filteredVideos.map((video) => (
                <Grid item xs={12} sm={12} md={6} lg={4} key={video.id}>
                  <VideoCard video={video} />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </ThemeProvider>
    </Container>
  );
};
