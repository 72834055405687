const petitionTitlesInitialState = {
  titles: [],
  loading: false,
  error: null,
};

export const petitionHistoryReducer = (state = {}, action) => {
  switch (action.type) {
    case "PETITION_HISTORY_REQUEST":
      return { loading: true };
    case "PETITION_HISTORY_SUCCESS":
      return {
        loading: false,
        history: action.payload,
        historyReset: false,
        chatId: action.payload.chatId,
      };
    case "PETITION_HISTORY_FAIL":
      return { loading: false, error: action.payload };
    case "PETITION_HISTORY_RESET":
      return {};
    default:
      return state;
  }
};

export const editPetitionTitleReducer = (state = {}, action) => {
  switch (action.type) {
    case "EDIT_PETITION_TITLE_REQUEST":
      return { loading: true };
    case "EDIT_PETITION_TITLE_SUCCESS":
      return { loading: false, message: action.payload, success: true };
    case "EDIT_PETITION_TITLE_FAIL":
      return { loading: false, error: action.payload };
    case "EDIT_PETITION_TITLE_RESET":
      return {};
    default:
      return state;
  }
};

export const deletePetitionReducer = (state = {}, action) => {
  switch (action.type) {
    case "DELETE_PETITION_REQUEST":
      return { loading: true };
    case "DELETE_PETITION_SUCCESS":
      return { loading: false, message: action.payload, success: true };
    case "DELETE_PETITION_FAIL":
      return { loading: false, error: action.payload };
    case "DELETE_PETITION_RESET":
      return {};
    default:
      return state;
  }
};

export const newPetitionReducer = (state = {}, action) => {
  switch (action.type) {
    case "NEW_PETITION_REQUEST":
      return { isClicked: true };
    case "NEW_PETITION_RESET":
      return {};
    default:
      return state;
  }
};

export const selectedPetitionReducer = (state = {}, action) => {
  switch (action.type) {
    case "SET_SELECTED_PETITION":
      return { selectedPetition: action.payload };
    case "SELECTED_PETITION_RESET":
      return {};
    default:
      return state;
  }
};

export const petitionTitlesReducer = (
  state = petitionTitlesInitialState,
  action
) => {
  switch (action.type) {
    case "GET_PETITION_TITLES_REQUEST":
      return { ...state, loading: true, error: "" };
    case "GET_PETITION_TITLES_SUCCESS":
      return {
        ...state,
        titles: action.payload,
        loading: false,
        error: "",
      };
    case "EDIT_PETITION_TITLE":
      return {
        ...state,
        titles: state.titles?.map((title) =>
          title.chat_id === action.payload.chatId
            ? { ...title, Title: action.payload.newTitle }
            : title
        ),
        error: "",
      };
    case "DELETE_PETITION_TITLE":
      return {
        ...state,
        titles: state.titles?.filter(
          (chat) => chat.chat_id !== action.payload.chatId
        ),
        error: "",
      };
    case "TOGGLE_PIN_PETITION":
      return {
        ...state,
        titles: state.titles?.map((chat) =>
          chat.chat_id === action.payload.chatId
            ? { ...chat, isPinned: action.payload.isPinned }
            : chat
        ),
        error: "",
      };
    case "UPDATE_IS_SAVED_PETITION":
      return {
        ...state,
        titles: state.titles?.map((chat) =>
          chat.chat_id === action.payload.chatId
            ? { ...chat, isSaved: true }
            : chat
        ),
      };
    case "GET_PETITION_TITLES_FAIL":
      return { loading: false, error: action.payload };
    case "GET_PETITION_TITLES_RESET":
      return petitionTitlesInitialState;
    default:
      return state;
  }
};
