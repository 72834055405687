import React from 'react';
import { TextField, MenuItem, Box } from '@mui/material';

const DropdownField = ({
  label,
  selectedValue,
  options,
  handleChange,
  isLoading,
}) => {
  return (
    <TextField
      label={label}
      variant="outlined"
      select
      size="small"
      value={selectedValue}
      onChange={(event) => handleChange(event.target.value)}
      InputProps={{
        sx: {
          bgcolor: 'white',
          fontSize: '13px',
        },
      }}
      InputLabelProps={{
        sx: {
          fontSize: '13px',
        },
      }}
      SelectProps={{
        MenuProps: {
          PaperProps: {
            sx: {
              '& .MuiMenuItem-root': {
                fontSize: '13px',
              },
              '& .MuiMenu-paper': {
                maxHeight: '180px',
              },
            },
          },
        },
      }}
    >
      {isLoading ? (
        <MenuItem value="" disabled>
          Loading...
        </MenuItem>
      ) : options?.length > 0 ? (
        options.map((option) => (
          <MenuItem
            key={option.Name}
            value={option}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Box>{option.Name}</Box>
              <Box
                sx={{
                  color:
                    option.status === 'Ready'
                      ? 'success.main'
                      : 'warning.main', // Green for Working, Yellow for Not Working
                  fontWeight: 'bold',
                }}
              >
                {option.status}
              </Box>
            </Box>
          </MenuItem>
        ))
      ) : (
        <MenuItem value="" disabled>
          No Options Available
        </MenuItem>
      )}
    </TextField>
  );
};

export default DropdownField;
