import React, { forwardRef } from "react";
import {
  Paper,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";

const SuggestionsDropdown = forwardRef(
  ({ suggestions, focusedIndex, onSuggestionClick, itemRefs, type }, ref) => {
    if (!suggestions?.length) return null;

    return (
      <Paper
        ref={ref}
        sx={{
          position: "absolute",
          top: "100%",
          left: 0,
          width: type === "statute" ? "85%" : "80%",
          //right: "20%",
          maxHeight: "180px",
          overflowY: "auto",
          zIndex: 10,
          marginTop: "4px",
          borderRadius: "8px",
          scrollBehavior: "smooth",
          "::-webkit-scrollbar": {
            width: "5px",
          },
          "::-webkit-scrollbar-track": {
            background: "#f1f1f1",
          },
          "::-webkit-scrollbar-thumb": {
            background: "#bbb",
            borderRadius: "6px",
          },
        }}
        elevation={3}
      >
        <List>
          {suggestions.map((suggestion, index) => (
            <ListItem key={index} disablePadding>
              <ListItemButton
                ref={(el) => (itemRefs.current[index] = el)}
                sx={{
                  paddingX: "12px",
                  paddingY: "2px",
                  backgroundColor:
                    focusedIndex === index
                      ? "rgba(0, 0, 0, 0.08)"
                      : "transparent",
                  "&:hover": {
                    backgroundColor: "rgba(23, 13, 13, 0.04)",
                  },
                }}
                disableRipple
                onClick={() => onSuggestionClick(suggestion.text)}
              >
                <ListItemText
                  primary={suggestion.text}
                  primaryTypographyProps={{ fontSize: "0.875rem" }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Paper>
    );
  }
);

export default SuggestionsDropdown;
