import React, { useState, useEffect } from "react";
import {
  List,
  ListItem,
  IconButton,
  Box,
  Stack,
  Typography,
  TextField,
  Button,
  Modal,
  ListItemButton,
  Menu,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import { showInsufficientCreditsModal } from "../../actions/creditActions";
import { deductCredits } from "../../utils/HelperFunction/deductCredits";
import {
  createNewGroup,
  deleteGroup,
  fetchBookmarkCases,
  fetchBookmarkGroups,
  fetchBookmarkStatutes,
  renameGroup,
} from "../../store/Bookmarks/bookmarksActions";

const BookmarksSidebar = ({ selectedGroup, setSelectedGroup }) => {
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const bookmarkGroups = useSelector((state) => state.bookmarkGroups);
  const { loading, groups } = bookmarkGroups;

  const [editingGroup, setEditingGroup] = useState(null);
  const [newGroupName, setNewGroupName] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [groupNameInput, setGroupNameInput] = useState("");
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [groupToDelete, setGroupToDelete] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const inputRef = React.useRef(null);

  const credits = useSelector((state) => state.credit.credits);
  const userId = useSelector((state) => state.credit.userId);

  const handleGroupClick = (event, group) => {
    event.stopPropagation();
    setSelectedGroup({ id: group._id, name: group.groupName });
    dispatch(fetchBookmarkCases(group));
    dispatch(fetchBookmarkStatutes(group));
    setEditingGroup(null);
    setMenuAnchorEl(null);
  };

  const confirmDeleteGroup = (e, groupId) => {
    e.stopPropagation();
    setGroupToDelete(groupId);
    setConfirmationModalOpen(true);
    setMenuAnchorEl(null);
  };

  const handleDeleteGroup = async () => {
    const groupId = groupToDelete;
    const groupName = groups.find((group) => group._id === groupId).groupName;

    if (groupName === "Bookmarks") return;

    try {
      let creditsToDeduct = 1;
      if (credits <= 0 || credits <= creditsToDeduct) {
        dispatch(showInsufficientCreditsModal());
        return;
      }
      dispatch(deleteGroup(userInfo?._id, groupId));
      setSelectedGroup({ id: groups[0]?._id, name: groups[0]?.groupName });
      dispatch(fetchBookmarkCases(groups[0]));
      dispatch(fetchBookmarkStatutes(groups[0]));
      const deductionSuccess = deductCredits(
        { userId, creditsToDeduct: 1, event: "Delete Bookmark Group" },
        dispatch
      );

      if (!deductionSuccess) {
        console.warn(
          "Failed to deduct credits. Ensure retry or user notification."
        );
      }
    } catch (error) {
      console.error("Error deleting the group:", error);
    } finally {
      setConfirmationModalOpen(false);
    }
  };

  const handleRenameGroup = async (e, groupId) => {
    e.stopPropagation();
    try {
      let creditsToDeduct = 1;
      if (credits <= 0 || credits <= creditsToDeduct) {
        dispatch(showInsufficientCreditsModal());
        return;
      }
      dispatch(renameGroup(userInfo?._id, groupId, newGroupName));
      setEditingGroup({ groupId: "", index: -1 });
      const deductionSuccess = deductCredits(
        { userId, creditsToDeduct: 1, event: "Rename bookmark group title" },
        dispatch
      );

      if (!deductionSuccess) {
        console.warn(
          "Failed to deduct credits. Ensure retry or user notification."
        );
      }
    } catch (error) {
      console.error("Error renaming the group:", error);
    }
  };

  const handleCreateGroup = async () => {
    if (!groupNameInput) return;

    try {
      let creditsToDeduct = 1;
      if (credits <= 0 || credits <= creditsToDeduct) {
        dispatch(showInsufficientCreditsModal());
        return;
      }
      dispatch(createNewGroup(userInfo._id, groupNameInput));
      setModalOpen(false);
      setGroupNameInput("");
      // Deduct credits using the helper
      const deductionSuccess = deductCredits(
        { userId, creditsToDeduct: 1, event: "Create Bookmark Group" },
        dispatch
      );

      if (!deductionSuccess) {
        console.warn(
          "Failed to deduct credits. Ensure retry or user notification."
        );
      }
    } catch (error) {
      console.error("Error creating the group:", error);
    }
  };

  const handleMenuOpen = (event, groupId) => {
    event.stopPropagation();
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (event) => {
    event.stopPropagation();
    setMenuAnchorEl(null);
  };

  const handleCancelEdit = (event) => {
    event.stopPropagation();
    setNewGroupName("");
    setEditingGroup(null);
  };

  const handleEditTitle = (event, groupId, groupName) => {
    event.stopPropagation();
    setEditingGroup(groupId);
    setNewGroupName(groupName);
    setMenuAnchorEl(null);
  };

  const handleInputChange = (e) => {
    const cursorPosition = inputRef.current.selectionStart;
    setNewGroupName(e.target.value);

    // Set the cursor back to where it was
    setTimeout(() => {
      inputRef.current.setSelectionRange(cursorPosition, cursorPosition);
    }, 0);
  };

  useEffect(() => {
    dispatch(fetchBookmarkGroups(userInfo._id, true));
  }, [userInfo, dispatch]);

  useEffect(() => {
    if (groups?.length > 0 && Object.keys(selectedGroup)?.length === 0) {
      setSelectedGroup({ id: groups[0]?._id, name: groups[0]?.groupName });
    }
  }, [groups, selectedGroup, dispatch]);

  useEffect(() => {
    setMenuAnchorEl(null);
  }, [selectedGroup]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minWidth: "278px",
        boxSizing: "border-box",
        p: "12px 0px 0 12px",
        flex: 1,
        bgcolor: "#f5f5f5",
        height: "calc(100vh - 50px)",
      }}
    >
      <Box
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          bgcolor: "#fff",
          p: "10px",
          borderRadius: "10px",
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
          overflowY: "auto",
          flexGrow: 1,
        }}
      >
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => setModalOpen(true)}
          sx={{
            backgroundColor: "#003D78",
            textTransform: "none",
            fontWeight: "bold",
            marginTop: "8px",
            marginBottom: "8px",
            "&:hover": { backgroundColor: "primary.main", color: "#fff" },
            transition: "background-color 0.3s ease-in-out",
          }}
        >
          New Group
        </Button>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              flexGrow: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress size={20} />
          </Box>
        ) : (
          <List
            sx={{
              overflowY: "auto",
              "::-webkit-scrollbar": {
                width: "5px",
              },
              "::-webkit-scrollbar-track": {
                background: "#fff",
              },
              "::-webkit-scrollbar-thumb": {
                background: "#bbb",
                borderRadius: "6px",
              },
            }}
          >
            <Typography
              variant="overline"
              sx={{
                display: "flex",
                gap: 0.5,
                alignItems: "center",
                padding: "4px 16px 0px 12px",
                color: "#3C50B1",
                fontWeight: "bold",
                textTransform: "none",
              }}
            >
              Bookmark Groups
            </Typography>
            {groups?.map((group, index) => (
              <ListItem key={group._id} disablePadding>
                <ListItemButton
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    height: "38px",
                    padding: "8px 12px",
                    borderRadius: "8px",
                    mr: 1,
                    "&.Mui-selected": {
                      backgroundColor: "#ECECEC",
                      "&:hover": {
                        backgroundColor: "#ECECEC",
                      },
                    },
                    transition: "background-color 0.3s ease",
                    "&:hover": {
                      backgroundColor: "#ECECEC",
                    },
                  }}
                  selected={
                    selectedGroup.id === group._id || editingGroup === group._id
                  }
                  onClick={(e) => handleGroupClick(e, group)}
                >
                  {editingGroup === group._id ? (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <input
                        ref={inputRef}
                        style={{
                          width: "100%",
                          padding: "4px",
                          marginLeft: "0",
                          fontSize: "13px",
                        }}
                        type="text"
                        autoFocus
                        value={newGroupName}
                        onClick={(e) => e.stopPropagation()}
                        onFocus={(e) => e.stopPropagation()}
                        onChange={handleInputChange}
                      />
                      <IconButton
                        edge="end"
                        onClick={(e) => handleRenameGroup(e, group._id)}
                      >
                        <CheckIcon fontSize="small" />
                      </IconButton>
                      <IconButton
                        edge="end"
                        onClick={(e) => handleCancelEdit(e)}
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  ) : (
                    <>
                      <Stack
                        direction="row"
                        width="100%"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Typography
                          variant="subtitle2"
                          noWrap
                          sx={{
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            fontSize: "13px",
                            flexGrow: 1,
                          }}
                        >
                          {group.groupName}
                        </Typography>
                        {selectedGroup.id === group._id &&
                          selectedGroup.name !== "Bookmarks" && (
                            <>
                              <IconButton
                                size="small"
                                aria-label="options"
                                onClick={(e) => {
                                  handleMenuOpen(e, group._id);
                                }}
                              >
                                <MoreHorizIcon />
                              </IconButton>
                              <Menu
                                anchorEl={menuAnchorEl}
                                open={Boolean(menuAnchorEl)}
                                onClose={handleMenuClose}
                              >
                                <MenuItem
                                  sx={{ fontSize: "14px" }}
                                  onClick={(e) => {
                                    handleEditTitle(
                                      e,
                                      group?._id,
                                      group?.groupName
                                    );
                                  }}
                                >
                                  <EditIcon sx={{ fontSize: 16, mr: 1 }} />
                                  Edit
                                </MenuItem>
                                <MenuItem
                                  sx={{
                                    fontSize: "14px",
                                    color: "#d32f2f",
                                  }}
                                  onClick={(e) =>
                                    confirmDeleteGroup(e, group?._id)
                                  }
                                >
                                  <DeleteIcon
                                    color="error"
                                    sx={{ fontSize: 16, mr: 1 }}
                                  />
                                  Delete
                                </MenuItem>
                              </Menu>
                            </>
                          )}
                      </Stack>
                    </>
                  )}
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        )}
      </Box>
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 300,
            bgcolor: "white",
            p: 4,
            boxShadow: 24,
            borderRadius: 2,
          }}
        >
          <Typography
            sx={{
              color: "#303f9f",
              fontSize: "18px",
              fontWeight: "bold",
            }}
          >
            Create New Group
          </Typography>
          <TextField
            variant="outlined"
            size="small"
            label="Group Name"
            value={groupNameInput}
            onChange={(e) => setGroupNameInput(e.target.value)}
            fullWidth
            sx={{ mt: 2 }}
            InputProps={{
              sx: {
                bgcolor: "white",
                fontSize: "13px",
                "& input:-webkit-autofill": {
                  backgroundColor: "white !important",
                  WebkitBoxShadow: "0 0 0 30px white inset !important",
                  WebkitTextFillColor: "black !important",
                },
              },
            }}
            InputLabelProps={{
              sx: {
                fontSize: "13px",
              },
            }}
          />
          <Button
            variant="contained"
            onClick={handleCreateGroup}
            sx={{
              mt: 2,
              width: "100%",
              textTransform: "none",
              backgroundColor: "#003D78",
              "&:hover": { backgroundColor: "primary.main", color: "#fff" },
            }}
          >
            Create
          </Button>
        </Box>
      </Modal>
      <Modal
        open={confirmationModalOpen}
        onClose={() => setConfirmationModalOpen(false)}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400, // Adjust width for better alignment with the dialog
            bgcolor: "white",
            p: 3,
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
            borderRadius: "16px",
          }}
        >
          {/* Modal Title */}
          <Typography
            variant="h6"
            sx={{
              fontSize: "18px",
              color: "#303f9f",
              fontWeight: "bold",
              borderBottom: "1px solid #e0e0e0",
              pb: 2,
            }}
          >
            Confirm Deletion
          </Typography>

          {/* Modal Content */}
          <Typography variant="body1" textAlign="center" sx={{ mt: 3, mb: 1 }}>
            Are you sure you want to delete this group?
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            textAlign="center"
            sx={{ mb: 3 }}
          >
            This action cannot be undone.
          </Typography>

          {/* Modal Actions */}
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button
              onClick={() => setConfirmationModalOpen(false)}
              color="primary"
              variant="outlined"
              sx={{
                borderRadius: "24px",
                padding: "8px 24px",
                textTransform: "none",
                fontWeight: "bold",
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleDeleteGroup}
              color="error"
              variant="contained"
              sx={{
                borderRadius: "24px",
                padding: "8px 24px",
                textTransform: "none",
                fontWeight: "bold",
                ml: 2,
              }}
            >
              Delete
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default BookmarksSidebar;
