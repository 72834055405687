import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import StatuteDetailSidebar from "./layout/StatuteDetailSidebar";
import StatuteDetail from "./StatuteDetail";
import AskChatbot from "./layout/AskChatbot";

const StatuteDetailDialog = ({ open, onClose }) => {
  const statuteSections = useSelector((state) => state.statuteSections);
  const { sections } = statuteSections;

  const [selectedStatuteSection, setSelectedStatuteSection] = useState(0);
  const [statuteContent, setStatuteContent] = useState("");

  const handleStatuteSectionClick = (section) => {
    setSelectedStatuteSection(section);
  };

  useEffect(() => {
    if (sections?.length > 0) {
      const context = sections?.[0]?.related_data
        ?.map((section) => section.Statute)
        .join(", ");
      setStatuteContent(context);
    }
  }, [sections]);

  if (!open) return null;
  return (
    <Box
      sx={{
        position: "absolute",
        width: "calc(100% - 45px)",
        height: "calc(100vh - 50px)",
        top: "50px",
        left: "45px",
        //inset: 0, // Covers the entire viewport
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1, // Higher z-index to overlay on top of other elements
      }}
      onClick={onClose}
    >
      {/* Content container */}
      <Box
        onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the content
        sx={{
          position: "relative",
          width: "calc(100vw - 45px)",
          height: "calc(100vh - 50px)",
          backgroundColor: "white",
          display: "flex",
          flexDirection: "row",
          overflowY: "clip",
        }}
      >
        <StatuteDetailSidebar
          onSectionClick={handleStatuteSectionClick}
          selectedSection={selectedStatuteSection}
          onClose={onClose}
        />
        <StatuteDetail selectedSection={selectedStatuteSection} />
        <AskChatbot chatContext={statuteContent} component={"statute"} />
      </Box>
    </Box>
  );
};

export default StatuteDetailDialog;
