import ReactGA from "react-ga4";
import { store } from "./store"; // Adjust the import path to your actual store location

//const GA_TRACKING_ID = 'G-MLMSMEKZ2K'; // Replace with your actual Google Analytics Measurement ID
const GA_TRACKING_ID = "G-QFD9FW77Y8";

// Initialize Google Analytics
export const initializeGA = () => {
  ReactGA.initialize(GA_TRACKING_ID);

  // Retrieve userInfo from Redux store
  const userLogin = store.getState().userLogin;
  const { userInfo } = userLogin;

  // If userInfo exists, set user_id globally
  if (userInfo && userInfo._id) {
    ReactGA.set({ user_id: userInfo._id });
  }
};

// Deinitialize Google Analytics
export const deinitializeGA = () => {
  ReactGA.set({ user_id: null }); // Clear the user_id
};

// Track page views
export const trackPageView = (path) => {
  ReactGA.send({ hitType: "pageview", page: path });
};

// Track time stamps
export const trackEventWithResponseTime = (category, action, label, value) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
    value: value,
  });
};

// Track events
export const trackEvent = (category, action, label) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  });
};
