import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import Sidebar from "./Sidebar";
import PetitionForm from "./PetitionForm";
import PetitionPreview from "./PetitionPreview";
import HistoryTable from "./HistoryTable";
const PetitionDrafting = () => {
  const dispatch = useDispatch();

  const newPetitionRequest = useSelector((state) => state.newPetitionRequest);
  const { isClicked } = newPetitionRequest;
  const [readOnly, setReadOnly] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [generatedPetition, setGeneratedPetition] = useState({});
  const [isGenerating, setIsGenerating] = useState(false);
  const [isHistoryClicked, setIsHistoryClicked] = useState(false);
  const [petitionToEdit, setPetitionToEdit] = useState({});

  useEffect(() => {
    if (isClicked) {
      setGeneratedPetition({});
      dispatch({ type: "SELECTED_PETITION_RESET" });
      dispatch({ type: "NEW_PETITION_RESET" });
    }
    if (isHistoryClicked) {
      dispatch({ type: "SELECTED_PETITION_RESET" });
    }
  }, [isClicked, dispatch, isHistoryClicked]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        height: "100%",
        width: "100%",
      }}
    >
      <Sidebar
        setIsHistoryClicked={setIsHistoryClicked}
        setPetitionToEdit={setPetitionToEdit}
        setGeneratedPetition={setGeneratedPetition}
        readOnly={readOnly}
        setReadOnly={setReadOnly}
      />
      {isHistoryClicked ? (
        <HistoryTable
          setPetitionToEdit={setPetitionToEdit}
          setIsHistoryClicked={setIsHistoryClicked}
        />
      ) : (
        <React.Fragment>
          <PetitionForm
            setGeneratedPetition={setGeneratedPetition}
            setIsGenerating={setIsGenerating}
            isGenerating={isGenerating}
            readOnly={readOnly}
            setReadOnly={setReadOnly}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
          />
          <PetitionPreview
            readOnly={readOnly}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            setReadOnly={setReadOnly}
            generatedPetition={generatedPetition}
            setGeneratedPetition={setGeneratedPetition}
            isGenerating={isGenerating}
            petitionToEdit={petitionToEdit}
            setPetitionToEdit={setPetitionToEdit}
          />
        </React.Fragment>
      )}
    </Box>
  );
};

export default PetitionDrafting;
