import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import HomeSidebar from "../components/layout/HomeSidebar";
import HomeMainPage from "./HomeMainPage";
import Header from "../components/layout/Header";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import {
  hasSeenCreditsModal,
  setHasSeenLowCreditAlert,
  showLowCreditModal,
} from "../actions/creditActions";
import config from "../config/api";
import CongratulationsModal from "../components/Modal/CongratulationModal";
import CreditAlertModal from "../components/Modal/CreditAlertModal";
const baseUrl = process.env.REACT_APP_API_BASE_URL;
const HomePage = () => {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const { credits, hasSeenLowCreditAlert, isCreditsInitialized } = useSelector(
    (state) => state.credit
  );

  const [openModal, setOpenModal] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => setIsSidebarOpen((prev) => !prev);

  useEffect(() => {
    if (isCreditsInitialized) {
      if (credits < 10 && !hasSeenLowCreditAlert) {
        dispatch(showLowCreditModal(true));
      } else if (credits >= 10) {
        dispatch(setHasSeenLowCreditAlert(false));
      }
    }
  }, [credits, hasSeenLowCreditAlert, isCreditsInitialized, dispatch]);
  // Fetch `hasSeenModal` from Redux state
  const hasSeenModal = useSelector((state) => state.credit?.hasSeenCreditModal);
  useEffect(() => {
    if (!hasSeenModal) {
      setOpenModal(true);
    }
  }, []); // Include dependencies

  const handleClose = async () => {
    setOpenModal(false);
    try {
      const response = await fetch(`${baseUrl}/lawgptMarkModalSeen`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ userId: userInfo._id }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      if (data.hasSeenModal !== undefined) {
        dispatch(hasSeenCreditsModal(data.hasSeenModal)); // Dispatch action with the modal status
      }
    } catch (error) {
      console.error("Failed to update modal seen status", error);
    }
  };

  return userInfo ? (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Header handleSidebarToggle={toggleSidebar} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <HomeSidebar
          isOpen={isSidebarOpen}
          onClose={() => setIsSidebarOpen(false)}
        />
        <HomeMainPage />
      </Box>
      <CongratulationsModal open={openModal} onClose={handleClose} />
      <CreditAlertModal />
    </Box>
  ) : (
    <Navigate to="/" />
  );
};

export default HomePage;
